<template>
  <div class="text-center">
    <div
      class="big_title"
      :class="classes"
    >
      {{ $t(text) }}
    </div>
  </div>
</template>

<script>
export default {
  props: ['text', 'classes']
};
</script>

<style scoped>
.big_title {
  text-transform: uppercase;
  font-size: 9rem;
  line-height: 0.65;
  font-weight: bold;
}
@media (max-width: 1400px) {
  .big_title {
    font-size: 7rem;
  }
}
@media (max-width: 992px) {
  .big_title {
    font-size: 4.5rem;
  }
}
@media (max-width: 768px) {
  .big_title {
    font-size: 3.8rem;
  }
}
@media (max-width: 576px) {
  .big_title {
    font-size: 2.3rem;
  }
}
</style>
