const model = 'about';
const pageModel = 'page_' + model;
const imgModel = model + '/' + model;

export default {
  state: {
    slides: [
      {
        id: 1,
        isActive: true,
        name: 'page.brand',
        text: pageModel + '.slide1_text',
        src: require('@/assets/img/'+ imgModel +'_1.jpg'),
      },
      {
        id: 2,
        isActive: false,
        name: 'page.brand',
        text: pageModel + '.slide2_text',
        src: require('@/assets/img/'+ imgModel +'_2.jpg'),
      },
      {
        id: 3,
        isActive: false,
        name: 'page.brand',
        text: pageModel + '.slide3_text',
        src: require('@/assets/img/'+ imgModel +'_3.jpg'),
      },
    ],
    list_members: [
      {
        id: 1,
        position: pageModel + '.sec3_card1_pos',
        lastname: pageModel + '.sec3_card1_name',
        src: require('@/assets/img/directors/person_1.jpg'),
      },
      {
        id: 2,
        position: pageModel + '.sec3_card2_pos',
        lastname: pageModel + '.sec3_card2_name',
        src: require('@/assets/img/directors/person_2.jpg'),
      },
      {
        id: 3,
        position: pageModel + '.sec3_card3_pos',
        lastname: pageModel + '.sec3_card3_name',
        src: require('@/assets/img/directors/person_3.jpg'),
      },
      {
        id: 4,
        position: pageModel + '.sec3_card4_pos',
        lastname: pageModel + '.sec3_card4_name',
        src: require('@/assets/img/directors/person_4.jpg'),
      },
      {
        id: 5,
        position: pageModel + '.sec3_card5_pos',
        lastname: pageModel + '.sec3_card5_name',
        src: require('@/assets/img/directors/person_5.jpg'),
      },
      {
        id: 6,
        position: pageModel + '.sec3_card6_pos',
        lastname: pageModel + '.sec3_card6_name',
        src: require('@/assets/img/directors/person_6.jpg'),
      }
    ],
    list_management: [
      {
        id: 1,
        position: pageModel + '.sec3_card7_pos',
        lastname: pageModel + '.sec3_card7_name',
        src: require('@/assets/img/directors/person_7.jpg'),
      },
      {
        id: 2,
        position: pageModel + '.sec3_card8_pos',
        lastname: pageModel + '.sec3_card8_name',
        src: require('@/assets/img/directors/person_8.jpg'),
      },
      {
        id: 3,
        position: pageModel + '.sec3_card9_pos',
        lastname: pageModel + '.sec3_card9_name',
        src: require('@/assets/img/directors/person_9.jpg'),
      },
      {
        id: 4,
        position: pageModel + '.sec3_card10_pos',
        lastname: pageModel + '.sec3_card10_name',
        src: require('@/assets/img/directors/person_10.jpg'),
      },
      {
        id: 5,
        position: pageModel + '.sec3_card11_pos',
        lastname: pageModel + '.sec3_card11_name',
        src: require('@/assets/img/directors/person_11.jpg'),
      },
      {
        id: 6,
        position: pageModel + '.sec3_card12_pos',
        lastname: pageModel + '.sec3_card12_name',
        src: require('@/assets/img/directors/person_12.jpg'),
      },
      {
        id: 7,
        position: pageModel + '.sec3_card13_pos',
        lastname: pageModel + '.sec3_card13_name',
        src: require('@/assets/img/directors/person_13.jpg'),
      },
      {
        id: 8,
        position: pageModel + '.sec3_card14_pos',
        lastname: pageModel + '.sec3_card14_name',
        src: require('@/assets/img/directors/person_14.jpg'),
      },
      {
        id: 9,
        position: pageModel + '.sec3_card15_pos',
        lastname: pageModel + '.sec3_card15_name',
        src: require('@/assets/img/directors/person_15.jpg'),
      }
    ],
    list_associated: [
      {
        id: 1,
        position: pageModel + '.sec3_card16_pos',
        lastname: pageModel + '.sec3_card16_name',
        src: require('@/assets/img/directors/person_16.jpg'),
      },
      {
        id: 2,
        position: pageModel + '.sec3_card17_pos',
        lastname: pageModel + '.sec3_card17_name',
        src: require('@/assets/img/directors/person_17.jpg'),
      }
    ],
    list_numbers: [
      {
        id: 1,
        icon: require('@/assets/icons/collage-umsatz.svg'),
        title: 'page.i_total_sales_2021',
        desc: pageModel + '.sec3_i_desc1'
      },
      {
        id: 2,
        icon: require('@/assets/icons/ebit.svg'),
        title: 'page.i_ebit_2021',
        desc: pageModel + '.sec3_i_desc2'
      },
      {
        id: 3,
        icon: require('@/assets/icons/people.svg'),
        title: "page.i_number_of_employees_2022",
        desc: pageModel + '.sec3_i_desc3'
      },
      {
        id: 4,
        icon: require('@/assets/icons/experience.svg'),
        title: "page.i_founded",
        desc: pageModel + '.sec3_i_desc4'
      },
      {
        id: 5,
        icon: require('@/assets/icons/swiss-quality.svg'),
        title: "page.i_shareholders",
        desc: pageModel + '.sec3_i_desc5'
      },
      {
        id: 6,
        icon: require('@/assets/icons/plane-up.svg'),
        title: "page.i_aircraft_delivered",
        desc: pageModel + '.sec3_i_desc6'
      }
    ],
  
    img1: require('@/assets/img/'+ imgModel +'_1.jpg'),
    
    img2: require('@/assets/img/'+ imgModel +'_4.jpg'),
    img3: require('@/assets/img/'+ imgModel +'_5.jpg'),
    img4: require('@/assets/img/'+ imgModel +'_6.jpg'),
    img5: require('@/assets/img/'+ imgModel +'_7.jpg'),
    
    img6: require('@/assets/img/'+ imgModel +'_8.jpg'),
    img7: require('@/assets/img/'+ imgModel +'_9.jpg'),
    img8: require('@/assets/img/'+ imgModel +'_10.jpg'),
  
    img9: require('@/assets/img/'+ imgModel +'_11.jpg'),
    img10: require('@/assets/img/'+ imgModel +'_12.jpg'),
    img11: require('@/assets/img/'+ imgModel +'_13.jpg'),
    img12: require('@/assets/img/'+ imgModel +'_14.jpg'),
  
    img13: require('@/assets/img/'+ imgModel +'_15.jpg'),
  
    subtitle1: 'page.usa_australia',
    subtitle2: 'page.our_numbers',
    subtitle3: 'page.engineering',
    subtitle4: 'page.engineering',
    subtitle5: 'page.manufacturing',
    subtitle6: 'page.quality_safety',
    subtitle7: 'page.sustainability',
    subtitle8: 'page.our_credo',
    subtitle9: 'page.iso_certification',
    
    text1: 'page.about',
    
    text2: pageModel + '.sec2_title1',
    text3: pageModel + '.sec2_text1',
    text4: pageModel + '.sec2_text2',
    
    text5: pageModel + '.sec3_bigtitle1',
    text6: pageModel + '.sec3_bigtitle2',
    text7: pageModel + '.sec3_title1',
    text8: pageModel + '.sec3_text1_1',
    text9: pageModel + '.sec3_text1_2',
    text10: pageModel + '.sec3_title2',
    
    text11: pageModel + '.sec4_title1',
    text12: pageModel + '.sec4_text1_1',
    text13: pageModel + '.sec4_text1_2',
    text14: pageModel + '.sec4_title2',
    text15: pageModel + '.sec4_text2_1',
    text16: pageModel + '.sec4_text2_2',
    text17: pageModel + '.sec4_title3',
    text18: pageModel + '.sec4_text3_1',
    text19: pageModel + '.sec4_text3_2',
    text20: pageModel + '.sec4_text3_3',
    text21: pageModel + '.sec4_title4',
    text22: pageModel + '.sec4_text4_1',
    text23: pageModel + '.sec4_text4_2',
    text24: pageModel + '.sec4_title5',
    text25: pageModel + '.sec4_text5_1',
    text26: pageModel + '.sec4_text5_2',
  
    text27: pageModel + '.sec5_title1',
    text28: pageModel + '.sec5_text1_1',
    text29: pageModel + '.sec5_title2',
    text30: pageModel + '.sec5_text2_1',
    
    
    
    /*text20: pageModel + '.',*/
  }
}
