<template>
  <ul
    class="pagination"
    :class="classes"
  >
    <li class="pagination-item add-btn">
      <button
        type="button"
        @click="onClickFirstPage"
        :disabled="isInFirstPage"
      >
        <i class="fa-solid fa-backward-fast"></i>
      </button>
    </li>

    <li class="pagination-item add-btn">
      <button
        type="button"
        @click="onClickPreviousPage"
        :disabled="isInFirstPage"
      >
        <i class="fa-solid fa-backward-step"></i>
      </button>
    </li>

    <!-- Visible Buttons Start -->
    <li
      v-for="page in pages"
      class="pagination-item pag-nav-item"
    >
      <button
        type="button"
        @click="onClickPage(page.name)"
        :disabled="page.isDisabled"
        :class="{ active: isPageActive(page.name) }"
      >
        {{ page.name }}
      </button>
    </li>
    <!-- Visible Buttons End -->

    <li class="pagination-item add-btn">
      <button
        type="button"
        @click="onClickNextPage"
        :disabled="isInLastPage"
      >
        <i class="fa-solid fa-forward-step"></i>
      </button>
    </li>

    <li class="pagination-item add-btn">
      <button
        type="button"
        @click="onClickLastPage"
        :disabled="isInLastPage"
      >
        <i class="fa-solid fa-forward-fast"></i>
      </button>
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    maxVisibleButtons: {
      type: Number,
      required: false,
      default: 2
    },
    totalPages: {
      type: Number,
      required: true
    },
    total: {
      type: Number,
      required: true,
    },
    perPage: {
      type: Number,
      required: true,
    },
    currentPage: {
      type: Number,
      required: true,
    },
    classes: {
      type: String,
      required: false
    }
  },

  computed: {
    startPage() {
      if (this.currentPage === 1) {
        return 1;
      }

      if (this.currentPage === this.totalPages) {
        return this.totalPages - this.maxVisibleButtons + 1;
      }

      return this.currentPage;
    },

    endPage() {
      return Math.min(this.startPage + this.maxVisibleButtons - 1, this.totalPages);
    },

    pages() {
      const range = [];

      for (let i = this.startPage; i <= this.endPage; i++ ) {
        range.push({
          name: i,
          isDisabled: i === this.currentPage
        });
      }

      return range;
    },

    isInFirstPage() {
      return this.currentPage === 1;
    },

    isInLastPage() {
      return this.currentPage === this.totalPages;
    },
  },

  methods: {
    onClickFirstPage() {
      this.$emit('pagechanged', 1);
    },
    onClickPreviousPage() {
      this.$emit('pagechanged', this.currentPage - 1);
    },
    onClickPage(page) {
      this.$emit('pagechanged', page);
    },
    onClickNextPage() {
      this.$emit('pagechanged', this.currentPage + 1);
    },
    onClickLastPage() {
      this.$emit('pagechanged', this.totalPages);
    },
    isPageActive(page) {
      return this.currentPage === page;
    },
  }
}
</script>

<style>
.pagination {
  list-style-type: none;
}
.pagination-item {
  display: inline-block;
}
.pagination-item button:hover {
  color: var(--bs-light) !important;
  background-color: rgba(13, 110, 253, 0.85) !important;
  border: 1px solid var(--blue) !important;
}
.pagination-item button[disabled],
.add-btn button[disabled]:hover{
  background-color: #dadada !important;
  border: 1px solid gray !important;
  color: gray !important;
}
.pagination-item button {
  margin: 0 2px;
  padding: 4px 10px;
  background-color: var(--bs-light) !important;
  border: 1px solid var(--bs-gray) !important;
  color: var(--bs-gray) !important;
  border-radius: 10px 0 10px 0;
  min-width: 40px;
}
.pagination-item button.active {
  background-color: var(--blue) !important;
  border: 1px solid var(--blue) !important;
  color: var(--bs-light) !important;
}
.add-btn button {
  border: 1px solid var(--blue) !important;
  background-color: #cfeffc !important;
  color: var(--blue) !important;
}
</style>
