const model = 'history';
const pageModel = 'page_' + model;
const imgModel = model + '/' + model;

export default {
  state: {
    slides: [
      {
        id: 1,
        isActive: true,
        name: 'page.brand',
        text: pageModel + '.slide1_text',
        src: require('@/assets/img/'+ imgModel +'_1.jpg'),
      },
      {
        id: 2,
        isActive: false,
        name: 'page.brand',
        text: pageModel + '.slide2_text',
        src: require('@/assets/img/'+ imgModel +'_2.jpg'),
      },
      {
        id: 3,
        isActive: false,
        name: 'page.brand',
        text: pageModel + '.slide3_text',
        src: require('@/assets/img/'+ imgModel +'_3.jpg'),
      },
    ],
    list_history: [
      {
        id: 1,
        title: pageModel + '.sec4_nav_title1',
        text1: pageModel + '.sec4_text1_1',
        text2: pageModel + '.sec4_text1_2',
        text3: pageModel + '.sec4_text1_3',
        text4: pageModel + '.sec4_text1_4',
        src1: require('@/assets/img/'+ imgModel +'_16.jpg'),
        src2: require('@/assets/img/'+ imgModel +'_20.jpg'),
        src3: require('@/assets/img/'+ imgModel +'_21.jpg'),
      },
      {
        id: 2,
        title: pageModel + '.sec4_nav_title2',
        text1: pageModel + '.sec4_text2_1',
        text2: pageModel + '.sec4_text2_2',
        src1: require('@/assets/img/'+ imgModel +'_17.jpg'),
        src2: require('@/assets/img/'+ imgModel +'_18.jpg'),
        src3: require('@/assets/img/'+ imgModel +'_19.jpg'),
      },
      {
        id: 3,
        title: pageModel + '.sec4_nav_title3',
        text1: pageModel + '.sec4_text3_1',
        text2: pageModel + '.sec4_text3_2',
        text3: pageModel + '.sec4_text3_3',
        src1: require('@/assets/img/'+ imgModel +'_22.jpg'),
        src2: require('@/assets/img/'+ imgModel +'_23.jpg'),
        src3: require('@/assets/img/'+ imgModel +'_24.jpg'),
      },
      {
        id: 4,
        title: pageModel + '.sec4_nav_title4',
        text1: pageModel + '.sec4_text4_1',
        text2: pageModel + '.sec4_text4_2',
        text3: pageModel + '.sec4_text4_3',
        src1: require('@/assets/img/'+ imgModel +'_25.jpg'),
        src2: require('@/assets/img/'+ imgModel +'_26.jpg'),
        src3: require('@/assets/img/'+ imgModel +'_27.jpg'),
      },
      {
        id: 5,
        title: pageModel + '.sec4_nav_title5',
        text1: pageModel + '.sec4_text5_1',
        text2: pageModel + '.sec4_text5_2',
        text3: pageModel + '.sec4_text5_3',
        src1: require('@/assets/img/'+ imgModel +'_37.jpg'),
        src2: require('@/assets/img/'+ imgModel +'_38.jpg'),
        src3: require('@/assets/img/'+ imgModel +'_39.jpg'),
      },
      {
        id: 6,
        title: pageModel + '.sec4_nav_title6',
        text1: pageModel + '.sec4_text6_1',
        text2: pageModel + '.sec4_text6_2',
        src1: require('@/assets/img/'+ imgModel +'_28.jpg'),
        src2: require('@/assets/img/'+ imgModel +'_29.jpg'),
        src3: require('@/assets/img/'+ imgModel +'_30.jpg'),
      },
      {
        id: 7,
        title: pageModel + '.sec4_nav_title7',
        text1: pageModel + '.sec4_text7_1',
        text2: pageModel + '.sec4_text7_2',
        src1: require('@/assets/img/'+ imgModel +'_31.jpg'),
        src2: require('@/assets/img/'+ imgModel +'_32.jpg'),
        src3: require('@/assets/img/'+ imgModel +'_33.jpg'),
      },
      {
        id: 8,
        title: pageModel + '.sec4_nav_title8',
        text1: pageModel + '.sec4_text8_1',
        text2: pageModel + '.sec4_text8_2',
        text3: pageModel + '.sec4_text8_3',
        text4: pageModel + '.sec4_text8_4',
        src1: require('@/assets/img/'+ imgModel +'_34.jpg'),
        src2: require('@/assets/img/'+ imgModel +'_35.jpg'),
        src3: require('@/assets/img/'+ imgModel +'_36.jpg'),
      },
    ],
    list_model_range: [
      /*{
        id: 1,
        title: pageModel + '.sec5_title1',
        text1: pageModel + '.sec5_text1_1',
        text2: pageModel + '.sec5_text1_2',
        text3: pageModel + '.sec5_text1_3',
        text4: pageModel + '.sec5_text1_4',
        src: require('@/assets/img/'+ imgModel +'_40.jpg'),
      },*/
      {
        id: 2,
        title: pageModel + '.sec5_title2',
        text1: pageModel + '.sec5_text2_1',
        text2: pageModel + '.sec5_text2_2',
        text3: pageModel + '.sec5_text2_3',
        text4: pageModel + '.sec5_text2_4',
        src: require('@/assets/img/'+ imgModel +'_5.jpg'),
        src1: require('@/assets/img/'+ imgModel +'_41.jpg'),
        src2: require('@/assets/img/'+ imgModel +'_42.jpg'),
        src3: require('@/assets/img/'+ imgModel +'_43.jpg'),
      },
      {
        id: 3,
        title: pageModel + '.sec5_title3',
        text1: pageModel + '.sec5_text3_1',
        text2: pageModel + '.sec5_text3_2',
        text3: pageModel + '.sec5_text3_3',
        text4: pageModel + '.sec5_text3_4',
        src: require('@/assets/img/'+ imgModel +'_44.jpg'),
      },
      {
        id: 4,
        title: pageModel + '.sec5_title4',
        text1: pageModel + '.sec5_text4_1',
        text2: pageModel + '.sec5_text4_2',
        text4: pageModel + '.sec5_text4_4',
        src: require('@/assets/img/'+ imgModel +'_45.jpg'),
      },
      {
        id: 5,
        title: pageModel + '.sec5_title5',
        text1: pageModel + '.sec5_text5_1',
        text2: pageModel + '.sec5_text5_2',
        text3: pageModel + '.sec5_text5_3',
        text4: pageModel + '.sec5_text5_4',
        src: require('@/assets/img/'+ imgModel +'_49.jpg'),
        src1: require('@/assets/img/'+ imgModel +'_46.jpg'),
        src2: require('@/assets/img/'+ imgModel +'_47.jpg'),
        src3: require('@/assets/img/'+ imgModel +'_48.jpg'),
      },
      {
        id: 6,
        title: pageModel + '.sec5_title6',
        text1: pageModel + '.sec5_text6_1',
        text2: pageModel + '.sec5_text6_2',
        text3: pageModel + '.sec5_text6_3',
        text4: pageModel + '.sec5_text6_4',
        src: require('@/assets/img/'+ imgModel +'_50.jpg'),
        src1: require('@/assets/img/'+ imgModel +'_51.jpg'),
        src2: require('@/assets/img/'+ imgModel +'_52.jpg'),
        src3: require('@/assets/img/'+ imgModel +'_53.jpg'),
      },
      {
        id: 7,
        title: pageModel + '.sec5_title7',
        text1: pageModel + '.sec5_text7_1',
        text2: pageModel + '.sec5_text7_2',
        text3: pageModel + '.sec5_text7_3',
        text4: pageModel + '.sec5_text7_4',
        src: require('@/assets/img/'+ imgModel +'_6.jpg'),
      },
      {
        id: 8,
        title: pageModel + '.sec5_title8',
        text1: pageModel + '.sec5_text8_1',
        text2: pageModel + '.sec5_text8_2',
        text3: pageModel + '.sec5_text8_3',
        text5: pageModel + '.sec5_text8_5',
        text4: pageModel + '.sec5_text8_4',
        src: require('@/assets/img/'+ imgModel +'_22.jpg'),
        src1: require('@/assets/img/'+ imgModel +'_54.jpg'),
        src2: require('@/assets/img/'+ imgModel +'_55.jpg'),
        src3: require('@/assets/img/'+ imgModel +'_56.jpg'),
      },
      {
        id: 9,
        title: pageModel + '.sec5_title9',
        text1: pageModel + '.sec5_text9_1',
        text2: pageModel + '.sec5_text9_2',
        text3: pageModel + '.sec5_text9_3',
        text4: pageModel + '.sec5_text9_4',
        src: require('@/assets/img/'+ imgModel +'_57.jpg'),
        src1: require('@/assets/img/'+ imgModel +'_58.jpg'),
        src2: require('@/assets/img/'+ imgModel +'_59.jpg'),
        src3: require('@/assets/img/'+ imgModel +'_60.jpg'),
      },
      {
        id: 10,
        title: pageModel + '.sec5_title10',
        text1: pageModel + '.sec5_text10_1',
        text2: pageModel + '.sec5_text10_2',
        text3: pageModel + '.sec5_text10_3',
        text4: pageModel + '.sec5_text10_4',
        src: require('@/assets/img/'+ imgModel +'_23.jpg'),
        src1: require('@/assets/img/'+ imgModel +'_9.jpg'),
        src2: require('@/assets/img/'+ imgModel +'_61.jpg'),
        src3: require('@/assets/img/'+ imgModel +'_62.jpg'),
      },
      {
        id: 11,
        title: pageModel + '.sec5_title11',
        text1: pageModel + '.sec5_text11_1',
        text2: pageModel + '.sec5_text11_2',
        text3: pageModel + '.sec5_text11_3',
        text4: pageModel + '.sec5_text11_4',
        src: require('@/assets/img/'+ imgModel +'_63.jpg'),
        src1: require('@/assets/img/'+ imgModel +'_64.jpg'),
        src2: require('@/assets/img/'+ imgModel +'_65.jpg'),
        src3: require('@/assets/img/'+ imgModel +'_66.jpg'),
      },
      {
        id: 12,
        title: pageModel + '.sec5_title12',
        text1: pageModel + '.sec5_text12_1',
        text2: pageModel + '.sec5_text12_2',
        text3: pageModel + '.sec5_text12_3',
        text4: pageModel + '.sec5_text12_4',
        src: require('@/assets/img/'+ imgModel +'_67.jpg'),
        src1: require('@/assets/img/'+ imgModel +'_68.jpg'),
        src2: require('@/assets/img/'+ imgModel +'_69.jpg'),
        src3: require('@/assets/img/'+ imgModel +'_70.jpg'),
      },
      {
        id: 13,
        title: pageModel + '.sec5_title13',
        text1: pageModel + '.sec5_text13_1',
        text2: pageModel + '.sec5_text13_2',
        text3: pageModel + '.sec5_text13_3',
        text4: pageModel + '.sec5_text13_4',
        src: require('@/assets/img/'+ imgModel +'_73.jpg'),
        src1: require('@/assets/img/'+ imgModel +'_71.jpg'),
        src2: require('@/assets/img/'+ imgModel +'_72.jpg'),
        src3: require('@/assets/img/'+ imgModel +'_74.jpg'),
      },
      {
        id: 14,
        title: pageModel + '.sec5_title14',
        text1: pageModel + '.sec5_text14_1',
        text2: pageModel + '.sec5_text14_2',
        text3: pageModel + '.sec5_text14_3',
        text4: pageModel + '.sec5_text14_4',
        src: require('@/assets/img/'+ imgModel +'_75.jpg'),
        src1: require('@/assets/img/'+ imgModel +'_76.jpg'),
        src2: require('@/assets/img/'+ imgModel +'_77.jpg'),
        src3: require('@/assets/img/'+ imgModel +'_78.jpg'),
      },
      {
        id: 15,
        title: pageModel + '.sec5_title15',
        text1: pageModel + '.sec5_text15_1',
        text2: pageModel + '.sec5_text15_2',
        text3: pageModel + '.sec5_text15_3',
        text4: pageModel + '.sec5_text15_4',
        src: require('@/assets/img/'+ imgModel +'_80.jpg'),
        src1: require('@/assets/img/'+ imgModel +'_81.jpg'),
        src2: require('@/assets/img/'+ imgModel +'_82.jpg'),
        src3: require('@/assets/img/'+ imgModel +'_83.jpg'),
      },
    ],
    list_tutorials: [
      {
        id: 1,
        isActive: true,
        src: require('@/assets/img/'+ imgModel + '_5.jpg'),
        name: pageModel + '.modal_text1',
      },
      {
        id: 2,
        isActive: false,
        src: require('@/assets/img/'+ imgModel + '_4.jpg'),
        name: pageModel + '.modal_text2',
      },
      {
        id: 3,
        isActive: false,
        src: require('@/assets/img/'+ imgModel + '_50.jpg'),
        name: pageModel + '.modal_text3',
      },
      {
        id: 4,
        isActive: false,
        src: require('@/assets/img/'+ imgModel + '_54.jpg'),
        name: pageModel + '.modal_text4',
      },
      {
        id: 5,
        isActive: false,
        src: require('@/assets/img/'+ imgModel + '_58.jpg'),
        name: pageModel + '.modal_text5',
      },
      {
        id: 6,
        isActive: false,
        src: require('@/assets/img/'+ imgModel + '_65.jpg'),
        name: pageModel + '.modal_text6',
      },
      {
        id: 7,
        isActive: false,
        src: require('@/assets/img/'+ imgModel + '_67.jpg'),
        name: pageModel + '.modal_text7',
      },
      {
        id: 8,
        isActive: false,
        src: require('@/assets/img/'+ imgModel + '_71.jpg'),
        name: pageModel + '.modal_text8',
      },
      {
        id: 9,
        isActive: false,
        src: require('@/assets/img/'+ imgModel + '_77.jpg'),
        name: pageModel + '.modal_text9',
      },
      {
        id: 10,
        isActive: false,
        src: require('@/assets/img/'+ imgModel + '_81.jpg'),
        name: pageModel + '.modal_text10',
      }
    ],
    list_modal_tutorials: [
      {
        id: 1,
        frameSrc: "https://youtu.be/Umf3SU3oFcY",
        height_video: "280px"
      },
      {
        id: 2,
        frameSrc: "https://youtu.be/ROQTIxd757Y",
        height_video: "280px"
      },
      {
        id: 3,
        frameSrc: "https://youtu.be/wcneYYs2NMI",
        height_video: "280px"
      },
      {
        id: 4,
        frameSrc: "https://youtu.be/C63zsloLdPM",
        height_video: "280px"
      },
      {
        id: 5,
        frameSrc: "https://youtu.be/KC_xpiAjo4c",
        height_video: "280px"
      },
      {
        id: 6,
        frameSrc: "https://youtu.be/-h_UqFd1BM8",
        height_video: "280px"
      },
      {
        id: 7,
        frameSrc: "https://youtu.be/QSb7nEkcL94",
        height_video: "280px"
      },
      {
        id: 8,
        frameSrc: "https://youtu.be/XD7rLi9NH7Q?list=PLe3AeLDxXYOMayjAM9pbDsHDrDJdx0Tcc",
        height_video: "280px"
      },
      {
        id: 9,
        frameSrc: "https://youtu.be/_bPcWpauR_I",
        height_video: "280px"
      },
      {
        id: 10,
        frameSrc: "https://youtu.be/Y-oYdvXY3Mc?list=PLe3AeLDxXYOPCJDWiJo_DEbEjDHXDR-3n",
        height_video: "280px"
      }
    ],
    
    img1: require('@/assets/img/'+ imgModel +'_4.jpg'),
    img2: require('@/assets/img/'+ imgModel +'_5.jpg'),
    img3: require('@/assets/img/'+ imgModel +'_6.jpg'),
    img4: require('@/assets/img/'+ imgModel +'_7.jpg'),
    img5: require('@/assets/img/'+ imgModel +'_8.jpg'),
    img6: require('@/assets/img/'+ imgModel +'_9.jpg'),
    
    subtitle1: 'page.models',
    
    text1: 'page.history',
    
    text2: pageModel + '.sec2_title1',
    text3: pageModel + '.sec2_text1',
    
    text4: pageModel + '.sec3_title1',
    text5: pageModel + '.sec3_text1_1',
    text6: pageModel + '.sec3_text1_2',
    text7: pageModel + '.sec3_title2',
    text8: pageModel + '.sec3_text2_1',
    text9: pageModel + '.sec3_title3',
    text10: pageModel + '.sec3_text3_1',
    text11: pageModel + '.sec3_text3_2',
    text12: pageModel + '.sec3_text3_3',
    text13: pageModel + '.sec3_title4',
    text14: pageModel + '.sec3_text4_1',
    text15: pageModel + '.sec3_bigtitle1',
  
    text16: pageModel + '.sec5_title',
    text17: pageModel + '.sec5_text0_1',
    text18: pageModel + '.sec5_text0_2',
    /*text20: pageModel + '.',*/
  }
}
