<template>
  <picture
    class="p-2 bg-opacity-10 position-relative"
    :class="classes || 'bg-white'"
  >
    <img
      class="w-100 bw"
      :src="src"
      alt="image"
    />
  </picture>
</template>

<script>
export default {
  props: ['src', 'classes']
};
</script>
