const model = 'contact';
const pageModel = 'page_' + model;
const imgModel = model + '/' + model;

export default {
  state: {
    list_data: [
      {
        id: 1,
        isActive: false,
        idx: 'buy_an_aircraft',
        icon: 'fa-solid fa-plane',
        btn: pageModel + '.sec2_btn1',
        selected: false,
        list_models: [
          {
            id: 1,
            title: 'page.model1',
            src: require('@/assets/img/'+ imgModel +'_1.jpg'),
            list_office: [
              {
                id: 1,
                country: 'page.swi',
                name: 'page.brand',
                address: 'page.address',
                phone: 'page.phone',
              },
              {
                id: 2,
                country: 'page.usa',
                name: 'page.brand2',
                address: 'page.address2',
                phone: 'page.phone2',
              },
              {
                id: 3,
                country: 'page.aus',
                name: 'page.brand3',
                address: 'page.address3',
                phone: 'page.phone3',
              },
            ]
          },
          {
            id: 2,
            title: 'page.model2',
            src: require('@/assets/img/'+ imgModel +'_2.jpg'),
            list_office: [
              {
                id: 1,
                country: 'page.swi',
                name: 'page.brand',
                address: 'page.address',
                phone: 'page.phone',
              },
              {
                id: 2,
                country: 'page.usa',
                name: 'page.brand2',
                address: 'page.address2',
                phone: 'page.phone2',
              },
              {
                id: 3,
                country: 'page.aus',
                name: 'page.brand3',
                address: 'page.address3',
                phone: 'page.phone3',
              },
            ]
          },
          {
            id: 3,
            title: 'page.model3',
            src: require('@/assets/img/'+ imgModel +'_3.jpg'),
            list_office: [
              {
                id: 1,
                country: 'page.swi',
                name: 'page.brand',
                address: 'page.address',
                phone: 'page.phone',
              }
            ]
          },
          {
            id: 4,
            title: 'page.model4',
            src: require('@/assets/img/'+ imgModel +'_4.jpg'),
            list_office: [
              {
                id: 1,
                country: 'page.swi',
                name: 'page.brand',
                address: 'page.address',
                phone: 'page.phone',
              }
            ]
          }
        ]
      },
      {
        id: 2,
        isActive: false,
        idx: 'customer_support',
        icon: 'fa-solid fa-headset',
        btn: pageModel + '.sec2_btn2',
        selected: true,
        list_models: [
          {
            id: 1,
            title: 'page.model1',
            src: require('@/assets/img/'+ imgModel +'_5.jpg'),
            list_office: [
              {
                id: 1,
                country: 'page.swi',
                name: 'page.brand',
                address: 'page.address',
                phone: 'page.phone',
              },
              {
                id: 2,
                country: 'page.usa',
                name: 'page.brand2',
                address: 'page.address2',
                phone: 'page.phone2',
              },
              {
                id: 3,
                country: 'page.aus',
                name: 'page.brand3',
                address: 'page.address3',
                phone: 'page.phone3',
              },
            ]
          },
          {
            id: 2,
            title: 'page.model2',
            src: require('@/assets/img/'+ imgModel +'_6.jpg'),
            list_office: [
              {
                id: 1,
                country: 'page.swi',
                name: 'page.brand',
                address: 'page.address',
                phone: 'page.phone',
              },
              {
                id: 2,
                country: 'page.usa',
                name: 'page.brand2',
                address: 'page.address2',
                phone: 'page.phone2',
              },
              {
                id: 3,
                country: 'page.aus',
                name: 'page.brand3',
                address: 'page.address3',
                phone: 'page.phone3',
              },
            ]
          },
          {
            id: 3,
            title: 'page.model3',
            src: require('@/assets/img/'+ imgModel +'_7.jpg'),
            list_office: [
              {
                id: 1,
                country: 'page.swi',
                name: 'page.brand',
                address: 'page.address',
                phone: 'page.phone',
              }
            ]
          },
          {
            id: 4,
            title: 'page.model4',
            src: require('@/assets/img/'+ imgModel +'_8.jpg'),
            list_office: [
              {
                id: 1,
                country: 'page.swi',
                name: 'page.brand',
                address: 'page.address',
                phone: 'page.phone',
              }
            ]
          }
        ]
      },
      {
        id: 3,
        isActive: false,
        idx: 'crew_training',
        icon: 'fa-solid fa-chalkboard-user',
        btn: pageModel + '.sec2_btn3',
        selected: true,
        list_models: [
          {
            id: 1,
            title: 'page.model1',
            src: require('@/assets/img/'+ imgModel +'_9.jpg'),
            list_office: [
              {
                id: 1,
                country: 'page.swi',
                name: 'page.brand',
                address: 'page.address',
                phone: 'page.phone',
              },
              {
                id: 2,
                country: 'page.usa',
                name: 'page.brand2',
                address: 'page.address2',
                phone: 'page.phone2',
              },
              {
                id: 3,
                country: 'page.aus',
                name: 'page.brand3',
                address: 'page.address3',
                phone: 'page.phone3',
              },
            ]
          },
          {
            id: 2,
            title: 'page.model2',
            src: require('@/assets/img/'+ imgModel +'_10.jpg'),
            list_office: [
              {
                id: 1,
                country: 'page.swi',
                name: 'page.brand',
                address: 'page.address',
                phone: 'page.phone',
              },
              {
                id: 2,
                country: 'page.usa',
                name: 'page.brand2',
                address: 'page.address2',
                phone: 'page.phone2',
              }
            ]
          },
          {
            id: 3,
            title: 'page.model3',
            src: require('@/assets/img/'+ imgModel +'_11.jpg'),
            list_office: [
              {
                id: 1,
                country: 'page.swi',
                name: 'page.brand',
                address: 'page.address',
                phone: 'page.phone',
              }
            ]
          },
          {
            id: 4,
            title: 'page.model4',
            src: require('@/assets/img/'+ imgModel +'_12.jpg'),
            list_office: [
              {
                id: 1,
                country: 'page.swi',
                name: 'page.brand',
                address: 'page.address',
                phone: 'page.phone',
              }
            ]
          }
        ]
      },
      {
        id: 4,
        isActive: false,
        idx: 'work_with_us',
        icon: 'fa-sharp fa-solid fa-users',
        btn: pageModel + '.sec2_btn4',
        selected: true,
        list_models: [
          {
            id: 1,
            title: 'page.address',
            src: require('@/assets/img/'+ imgModel +'_13.jpg'),
            list_office: [
              {
                id: 1,
                country: 'page.swi',
                name: 'page.brand',
                address: 'page.address',
                phone: 'page.phone',
              },
            ]
          },
          {
            id: 2,
            title: 'page.address2',
            src: require('@/assets/img/'+ imgModel +'_14.jpg'),
            list_office: [
              {
                id: 1,
                country: 'page.usa',
                name: 'page.brand2',
                address: 'page.address2',
                phone: 'page.phone2',
              }
            ]
          },
          {
            id: 3,
            title: 'page.address3',
            src: require('@/assets/img/'+ imgModel +'_15.jpg'),
            list_office: [
              {
                id: 1,
                country: 'page.aus',
                name: 'page.brand3',
                address: 'page.address3',
                phone: 'page.phone3',
              },
            ]
          },
        ]
      },
      {
        id: 5,
        isActive: false,
        idx: 'media_relations',
        icon: 'fa-solid fa-rectangle-ad',
        btn: pageModel + '.sec2_btn5',
        selected: true,
        list_models: [
          {
            id: 1,
            title: 'page.address',
            src: require('@/assets/img/'+ imgModel +'_16.jpg'),
            list_office: [
              {
                id: 1,
                country: 'page.swi',
                name: 'page.brand',
                address: 'page.address',
                phone: 'page.phone',
              },
            ]
          },
        ]
      }
    ],
    
    img1: require('@/assets/img/'+ imgModel +'_1.jpg'),
    
    img2: require('@/assets/img/'+ imgModel +'_17.jpg'),
    img3: require('@/assets/img/'+ imgModel +'_18.jpg'),
    img4: require('@/assets/img/'+ imgModel +'_19.jpg'),
    img5: require('@/assets/img/'+ imgModel +'_20.jpg'),
  
    subtitle0: 'page.contact',
    subtitle1: 'page.stans',
    
    text1: 'page.contact',
  
    text1_1: pageModel + '.sec2_title0',
    text1_2: pageModel + '.sec2_text0_1',
    text2: pageModel + '.sec2_title1',
    text4: pageModel + '.sec2_title2',
    text5: pageModel + '.sec2_text2_1',
    text6: pageModel + '.sec2_text2_2',
    
    text7: pageModel + '.sec2_bigtitle1',
    text8: pageModel + '.sec2_bigtitle2',
  
    text9: pageModel + '.sec3_title1',
    text10: pageModel + '.sec3_text1_1',
    text11: pageModel + '.sec3_text1_2',
    text12: pageModel + '.sec3_text1_3',
    
    
    /*text20: pageModel + '.',*/
  }
}
