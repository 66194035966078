<template>
  <div
    class="curtain a-center"
    :class="classes"
  >
    <ul class="w-100 d-flex justify-content-between">
      <li><span :class="colorBg"></span></li>
      <li><span :class="colorBg"></span></li>
      <li><span :class="colorBg"></span></li>
      <li><span :class="colorBg"></span></li>
      <li><span :class="colorBg"></span></li>
    </ul>
  </div>
</template>

<script>
export default {
  props: ['colorBg', 'classes']
}
</script>

<style scoped>
.curtain {
  width: 102%;
  height: 102%;
  z-index: 0;
}
ul {
  width: 100%;
  height: inherit;
}
li {
  height: inherit;
  width: 20%;
}
ul li span {
  display: block;
  width: 100%;
  height: 102%;
  animation: changeWidth 2.5s ease-in-out forwards;
}
@keyframes changeWidth {
  from {width: 100%;}
  to {width: 1%;}
}

</style>
