<template>
  <button
    :class="{ show:isShowArrow }"
    class="arrow-up"
    @click="moveUp"
    title="Scroll Up"
  >
    <i class="fa-solid fa-angle-up"/>
  </button>
</template>

<script>
import { ref, onMounted } from "vue";

export default {
  setup () {
    const isShowArrow = ref(false)

    onMounted(() => {
      window.addEventListener("scroll", () => {
        isShowArrow.value = window.pageYOffset > 3500;
      });
    });

    return {
      moveUp: () => window.scrollTo(0,0),
      isShowArrow
    }
  },

 components: {}
};
</script>

<style scoped>
.arrow-up {
  position: relative;
  border-width: 0 !important;
  width: 50px;
  height: 55px;
  right: -200px;
  border-radius: 10px 0 0 10px;
  background-color: var(--yellow);
  transition: 0.5s;
  opacity: 0.6;
}
.arrow-up svg {
  margin-top: 4px;
  color: var(--bs-black) !important;
  font-size: 1.2rem;
  font-weight: bold;
}
.show {
  right: 0px;
}
.arrow-up:hover {
  opacity: 1;
  box-shadow: 8px 11px 10px rgba(0, 0, 0, 0.2);
}

@media (max-width: 768px) {
  .arrow-up {
    width: 40px;
  }
}
</style>
