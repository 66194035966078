<template>
  <div
    :class="classes"
  >
    <picture class="col-4 col-lg-2">
      <img
        alt="brand-made-from"
        class="w-100"
        src="../../assets/img/craftedinswitzerland.svg">
    </picture>
  </div>
</template>

<script>
export default {
  props: ['classes']
}
</script>

<style scoped>
@media (max-width: 992px) {
  img {
    height: 14px !important;
  }
}

@media (max-width: 768px) {
  img {
    height: 13px !important;
  }
}

@media (max-width: 576px) {
  img {
    height: 11px !important;
  }
}
</style>
