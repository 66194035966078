<template>
  <section
    :id="id"
    :class="classes"
  >
    <div class="container">
      <slot />
    </div>
  </section>
</template>

<script>
export default {
  props: ['id', 'classes']
};
</script>
