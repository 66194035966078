<template>
  <div
    id="models-box-nav"
    :class="{ show:isShow }"
  >
    <ul>
      <li
        v-for="item in store.list_model_box"
        :key="item.id"
      >
        <router-link
        v-if="item.href"
        :to="{ name: item.href, params: { locale: this.$i18n.locale }}"
        class="text-black d-flex"
        :title="item.desc"
        @click="moveUp"
        >
          <strong v-if="item.name">
            {{ item.name }}
          </strong>
          <strong v-if="item.icon">
            <i :class="item.icon"/>
          </strong>
          <img
            :src="item.src"
            :alt="'image' + item.id"
          />
        </router-link>
      </li>
    </ul>

    <the-arrow-up />
  </div>
</template>

<script>
import { useStore } from "vuex";
import TheArrowUp from "@/components/TheArrowUp";
import { onMounted, ref } from "vue";

export default {
  setup() {
    const isShow = ref(false)

    onMounted(() => {
      window.addEventListener("scroll", () => {
        isShow.value = window.pageYOffset > 700;
      });
    });

    return {
      isShow,
      store: useStore().state.homeModule,
      moveUp: () => window.scrollTo(0,0),
    }
  },
  components: {
    TheArrowUp,
  },
};
</script>

<style scoped>
#models-box-nav {
  position: fixed;
  z-index: 10;
  top: 50%;
  right: -200px;
  transform: translate(0, -50%);
  transition: 0.5s;
}
#models-box-nav.show {
  right: -90px !important;
}
ul {
  position: relative;
  display: block;
  right: 0;
  width: 140px;
  margin: 0;
}
ul li {
  position: relative;
  cursor: pointer;
  width: inherit;
  right: 0;
  height: 55px;
  margin-bottom: 3px;
  border-radius: 10px 0 0 10px;
  background-color: var(--bs-gray);
  overflow: hidden;
  transition: 0.5s;
  opacity: 0.6;
}
ul li:nth-child(1) {
  background-color: var(--yellow);
}
ul li:hover {
  background-color: var(--yellow);
  box-shadow: 8px 11px 10px rgba(0, 0, 0, 0.2);
  right: 90px;
  opacity: 1;
}
.router-link-exact-active {
  background-color: var(--yellow) !important;
  color: var(--blue) !important;
}
li a strong {
  display: block;
  text-align: center;
  margin: auto;
  font-size: 0.6rem;
}
li a img {
  display: block;
  width: auto;
  height: 60px;
}
li a svg {
  font-size: 1.1rem;
}

@media (max-width: 768px) {
  ul {
    width: 130px;
  }
}
</style>
