<template>
  <div
    class="carousel carousel-fade p-0"
    data-bs-ride="carousel"
    :id="controlTargetId"
  >
    <ul class="carousel-inner mb-0">
      <li v-for="slide in slides"
          :key="slide.id"
          :class="[{ active: slide.isActive }, 'carousel-item']"
      >
        <div class="inner-slide">
          <div class="slide-image">
            <scroll-parallax>
              <img
                class="d-block w-100 img-parallax"
                :src="slide.src"
                :alt="'slide-offer' + slide.id"
              />
            </scroll-parallax>
            <!--https://www.npmjs.com/package/vue3-parallax-->
          </div>

          <my-curtain
            classes="curtain-mob"
            :color-bg="colorCurtainBg"
          />

          <div class="carousel-caption pt-0">
            <span class="text-white pt-0 pt-lg-5 px-3 lead text-bg-primary">
              {{ $t( slide.name ) }}
            </span>

            <my-curtain
              classes="curtain-desc"
              :color-bg="colorCurtainBg"
            />

            <div class="col col-lg-5">
              <h1 class="text-white my-3 my-lg-5">{{ $t( slide.text ) }}</h1>
              <router-link
                v-if="slide.href"
                :to="{ name: slide.href, params: { locale: this.$i18n.locale }}"
                class="btn btn-outline-primary position-relative"
              >
                {{ $t('page.learn_more') }}
              </router-link>
            </div>
          </div>

        </div>
      </li>
    </ul>

    <my-slider-control
      :slides="slides"
      :target="controlTargetId"
    />
  </div>
</template>

<script>
import MySliderControl from "@/components/ui/MySliderControl";
import ScrollParallax from 'vue3-parallax/src/components/ScrollParallax.vue';
import MyCurtain from "@/components/ui/MyCurtain";

export default {
  props: ['slides', 'controlTargetId', 'colorCurtainBg'],
  components: {
    MySliderControl,
    ScrollParallax,
    MyCurtain
  }
}
</script>

<style scoped>
.carousel {
  overflow: hidden;
  margin-bottom: 50px;
}
.slide-image {
  overflow: hidden;
  max-height: 650px;
}
.inner-slide {
  max-height: 650px;
  overflow: hidden;
}
.slide-image {
  scroll-snap-type: x mandatory;
}
.carousel-caption {
  background: var(--gradient);
  text-align: left;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  padding-left: 30px;
}
.carousel-caption span {
  display: inline-block;
}
.btn {
  opacity: 0;
  animation: show 2.4s forwards ease-in-out;
}
@keyframes show {
  85% {opacity: 0;}
  100% {opacity: 1;}
}
.curtain-mob {
  display: none;
}

@media (max-width: 1400px) {
  .slide-image {
    max-height: 600px;
  }
}
@media (max-width: 1200px) {
  .slide-image {
    max-height: 500px;
  }
}
@media (max-width: 992px) {
  .carousel {
    margin-top: 20px;
  }
  .inner-slide {
    display: flex;
    flex-direction: column;
  }
  .slide-image {
    max-height: 400px;
    order: 2;
  }
  .carousel-caption {
    position: relative;
    background: transparent;
    padding: 20px 0;
    text-align: center;
  }
  .carousel-caption h1 {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    padding: 12px 0;
  }
  .curtain-desc {
    display: none;
  }
  .curtain-mob {
    display: block;
  }
  .btn {
    opacity: 0;
    animation: show 0s forwards ease-in-out;
  }
}
@media (max-width: 756px) {
  .carousel {
    overflow: inherit !important;
  }
  .slide-image {
    bottom: 0;
    max-height: 300px;
    order: 2;
  }
}
@media (max-width: 576px) {
  .carousel {
    margin-top: 0;
  }
  .slide-image {
    max-height: 175px;
  }

}
</style>
