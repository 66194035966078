<template>
  <a v-if="link_text"
     :class="'btn mt-3 ' + classes"
     :href="href || '/#'">
    {{ $t(link_text) }}
  </a>
</template>

<script>
export default {
  props: ['classes', 'href', 'link_text']
};
</script>

<style>

</style>
