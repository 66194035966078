<template>
  <div
    :id="idModal"
    class="modal fade"
    aria-labelledby="ModalLabel"
    aria-hidden="true"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
  >
    <div class="modal-dialog modal-dialog-scrollable">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: ['idModal'],
};
</script>

<style scoped>

</style>
