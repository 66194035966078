const model = 'training';
const pageModel = 'page_' + model;
const imgModel = model + '/' + model;

export default {
  state: {
    slides: [
      {
        id: 1,
        isActive: true,
        name: 'page.brand',
        text: pageModel + '.slide1_text',
        src: require('@/assets/img/'+ imgModel +'_1.jpg'),
      },
      {
        id: 2,
        isActive: false,
        name: 'page.brand',
        text: pageModel + '.slide2_text',
        src: require('@/assets/img/'+ imgModel +'_2.jpg'),
      },
      {
        id: 3,
        isActive: false,
        name: 'page.brand',
        text: pageModel + '.slide3_text',
        src: require('@/assets/img/'+ imgModel +'_3.jpg'),
      },
    ],
    list_model_range: [
      {
        id: 1,
        title: pageModel + '.sec4_title1',
        text1: pageModel + '.sec4_text1_1',
        text2: pageModel + '.sec4_text1_2',
        text3: pageModel + '.sec4_text1_3',
        src: require('@/assets/img/'+ imgModel +'_10.jpg'),
        src1: require('@/assets/img/'+ imgModel +'_10.jpg'),
        src2: require('@/assets/img/'+ imgModel +'_12.jpg'),
      },
      {
        id: 2,
        title: pageModel + '.sec4_title2',
        text1: pageModel + '.sec4_text2_1',
        text3: pageModel + '.sec4_text2_2',
        src: require('@/assets/img/'+ imgModel +'_12.jpg'),
        src1: require('@/assets/img/'+ imgModel +'_12.jpg'),
        src2: require('@/assets/img/'+ imgModel +'_6.jpg'),
      },
      {
        id: 3,
        title: pageModel + '.sec4_title3',
        text1: pageModel + '.sec4_text3_1',
        text3: pageModel + '.sec4_text3_2',
        src: require('@/assets/img/'+ imgModel +'_6.jpg'),
        src1: require('@/assets/img/'+ imgModel +'_6.jpg'),
        src2: require('@/assets/img/'+ imgModel +'_8.jpg'),
      },
      {
        id: 4,
        title: pageModel + '.sec4_title4',
        text1: pageModel + '.sec4_text4_1',
        text2: pageModel + '.sec4_text4_2',
        text3: pageModel + '.sec4_text4_3',
        src: require('@/assets/img/'+ imgModel +'_8.jpg'),
        src1: require('@/assets/img/'+ imgModel +'_8.jpg'),
        src2: require('@/assets/img/'+ imgModel +'_9.jpg'),
      },
      {
        id: 5,
        title: pageModel + '.sec4_title5',
        text1: pageModel + '.sec4_text5_1',
        text2: pageModel + '.sec4_text5_2',
        text3: pageModel + '.sec4_text5_3',
        src: require('@/assets/img/'+ imgModel +'_9.jpg'),
        src1: require('@/assets/img/'+ imgModel +'_9.jpg'),
        src2: require('@/assets/img/'+ imgModel +'_11.jpg'),
      },
      {
        id: 6,
        title: pageModel + '.sec4_title6',
        text1: pageModel + '.sec4_text6_1',
        text3: pageModel + '.sec4_text6_2',
        src: require('@/assets/img/'+ imgModel +'_11.jpg'),
        src1: require('@/assets/img/'+ imgModel +'_11.jpg'),
        src2: require('@/assets/img/'+ imgModel +'_4.jpg'),
      },
      {
        id: 7,
        title: pageModel + '.sec4_title7',
        text1: pageModel + '.sec4_text7_1',
        text3: pageModel + '.sec4_text7_2',
        src: require('@/assets/img/'+ imgModel +'_5.jpg'),
        src1: require('@/assets/img/'+ imgModel +'_5.jpg'),
        src2: require('@/assets/img/'+ imgModel +'_7.jpg'),
      },
      {
        id: 8,
        title: pageModel + '.sec4_title8',
        text1: pageModel + '.sec4_text8_1',
        text2: pageModel + '.sec4_text8_2',
        text3: pageModel + '.sec4_text8_3',
        src: require('@/assets/img/'+ imgModel +'_7.jpg'),
        src1: require('@/assets/img/'+ imgModel +'_7.jpg'),
        src2: require('@/assets/img/'+ imgModel +'_9.jpg'),
      },
    ],
    
    img1: require('@/assets/img/'+ imgModel +'_4.jpg'),
    img2: require('@/assets/img/'+ imgModel +'_13.jpg'),
    img3: require('@/assets/img/'+ imgModel +'_14.jpg'),
    img4: require('@/assets/img/'+ imgModel +'_15.jpg'),
    img5: require('@/assets/img/'+ imgModel +'_16.jpg'),
    
    text1: 'page.training',
    
    text2: pageModel + '.sec2_title1',
    text3: pageModel + '.sec2_text1',
    text4: pageModel + '.sec2_text2',
    
    text5: pageModel + '.sec3_bigtitle1',
    text6: pageModel + '.sec3_bigtitle2',
    text7: pageModel + '.sec3_title1',
    text8: pageModel + '.sec3_text1_1',
    text9: pageModel + '.sec3_title2',
    text10: pageModel + '.sec3_text2_1',
    text11: pageModel + '.sec3_text2_2',
    text12: pageModel + '.sec3_text2_3',
  
    text13: pageModel + '.sec4_title0',
    text14: pageModel + '.sec4_text0_1',
    
    /*text20: pageModel + '.',*/
  }
}
