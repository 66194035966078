<template>
  <the-preloader/>
  <the-navbar/>
  <the-progress-bar/>
  <the-models-box/>
  <router-view ></router-view>
  <the-footer />
</template>

<script>
import ThePreloader from "@/components/ThePreloader";
import TheNavbar from '@/components/TheNavbar';
import TheProgressBar from "@/components/TheProgressBar";
import TheModelsBox from "@/components/TheModelsBox";
import TheFooter from "@/components/TheFooter";
import { onMounted } from "vue";

export default {
  components: {
    ThePreloader,
    TheNavbar,
    TheProgressBar,
    TheModelsBox,
    TheFooter
  },
}
</script>

<style scoped>

</style>
