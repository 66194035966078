<template>
  <div
    class="row m-0 p-0"
    :class="classes"
  >
    <div class="page-name mx-0 px-0 py-4 pt-md-3 text-center">
      <div class="d-inline-block">
        <span
          v-if="subtext"
          class="ms-0 text-secondary"
          :class="class_span ? class_span : 'ps-2'"
        >
          {{ $t('page.category') }}
        </span>
        <span
          v-else
          class="ms-0 text-secondary"
          :class="class_span ? class_span : 'ps-2'"
        >
          {{ $t('page.page') }}
        </span>
        <p class="py-4 mb-0 text-primary">
          {{ $t(text) }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
 export default {
   props: ['text', 'subtext', 'classes', 'class_span']
 }

 function onEntry(entry) {
   entry.forEach((change) => {
     if (change.isIntersecting) {
       change.target.classList.add('el-show');
     }
   });
 }

 let options = {
   threshold: [0.5],
 };

 let observer = new IntersectionObserver(onEntry, options);
 const elements = document.querySelectorAll('.el-animation');

 for (let elm of elements) {
   observer.observe(elm);
 }
</script>

<style scoped>
.page-name {
  color: #000;
  font-size: 5rem;
  font-weight: 100;
  line-height: 0;
}
.page-name span {
  display: block;
  font-size: 1rem;
  text-align: left;
  opacity: 0.5;
  letter-spacing: 0.03em;
}
.page-name p {
  text-transform: capitalize;
  line-height: 0.6;
  font-size: 5rem;
}
@media (max-width: 1200px) {
  .page-name p {
    font-size: 4.5rem;
  }
}
@media (max-width: 992px) {
  .page-name {
    border-bottom: 1px dotted rgba(255, 255, 255, 0.1);
    margin-left: -5px;
  }
}
@media (max-width: 756px) {
  .page-name p {
    font-size: 3.2rem;
  }
  .page-name span {
    display: block;
    margin-left: -4px !important;
  }
}
@media (max-width: 576px) {
  .page-name p {
    font-size: 2.7rem;
  }
}
</style>
