<template>
  <div
    class="row position-relative"
   :class="classes"
  >
    <ul
      class="btn-group m-0 p-0 justify-content-center"
      role="group"
      aria-label="Basic radio toggle button group"
    >
      <li>
        <input
          @click="params='down'"
          type="radio"
          class="btn-check"
          name="btn-radio"
          id="btn-radio1"
          autocomplete="off"
          checked
        >
        <label
          class="btn btn-outline-primary"
          for="btn-radio1"
        >
          <i class="fa-solid fa-arrow-down-wide-short"></i>
        </label>
      </li>

      <li class="mx-2">
        <input
          @click="params='up'"
          type="radio"
          class="btn-check"
          name="btn-radio"
          id="btn-radio2"
          autocomplete="off"
        >
        <label
          class="btn btn-outline-primary"
          for="btn-radio2"
        >
          <i class="fa-solid fa-arrow-up-wide-short"></i>
        </label>
      </li>

      <li>
        <input
          @click="params='news'"
          type="radio"
          class="btn-check"
          name="btn-radio"
          id="btn-radio3"
          autocomplete="off"
        >
        <label
          class="btn btn-outline-primary"
          for="btn-radio3"
        >
          {{ $t('page.news')}}
        </label>
      </li>

      <li class="ms-2">
        <input
          @click="params='event'"
          type="radio"
          class="btn-check"
          name="btn-radio"
          id="btn-radio4"
          autocomplete="off"
        >
        <label
          class="btn btn-outline-primary"
          for="btn-radio4"
        >
          {{ $t('page.event')}}
        </label>
      </li>
    </ul>
  </div>
  <ul
    id="cards-news"
    class="row my-0">
    <li
      v-for="card in cardsComputed"
      :key="card.id"
      class="card border-0 m-auto"
      :class="classes_col"
    >
      <div
        class="card-body bg-light overflow-hidden"
        :data-bs-target="'#modalBox' + card.id"
        data-bs-toggle="modal"
        type="button"
      >
        <div
          :class="class_link"
        >
          <div class="img-wrap overflow-hidden">
            <img
              class="card-img-top overflow-hidden bw"
              :src="card.src"
              :alt="'list-cards-news' + card.id"
            />
          </div>
          <div class="card-context">
            <div class="d-flex justify-content-between">
              <span class="text-uppercase card-mark d-inline-block px-2 py-1 text-bg-primary">
                {{ $t( card.mark ) }}
              </span>
              <strong class="card-date text-primary opacity-50 pt-1">
                {{ card.date }}
              </strong>
            </div>

            <h4 class="card-title mt-3 text-dark">
              {{ $t( card.title ) }}
            </h4>
            <p class="card-text text-dark">
              {{ $t( card.text1 ) }}
            </p>
            <div class="link">
              {{ $t('page.learn_more')}}
            </div>
          </div>

        </div>
      </div>
    </li>
  </ul>

  <!-- Pagination -->
  <my-pagination
    :totalPages="totalPages"
    :perPage="+perPageValue"
    :total="+limit"
    :currentPage="currentPage"
    :maxVisibleButtons="+maxVisibleButtons"
    @pagechanged="onPageChange"
    classes="justify-content-center mt-3"
   />

  <!--Modal News Item-->
  <div
    v-if="cards"
    class="modal-news-items"
  >
    <ul>
      <li
        v-for="modal in cards"
        :key="modal.id"
      >
        <modal-box
          :id-modal="'modalBox' + modal.id"
        >
          <div class="modal-content bg-primary text-light">

            <div class="modal-header bg-primary-dark">
              <h4 id="ModalLabel">
                {{ $t(modal.title) }}
              </h4>
            </div>

            <div class="modal-body p-3 m-0 h-100">

              <div class="image position-relative">
                <scroll-parallax>
                  <img
                    class="w-100"
                    :src="modal.src"
                    :alt="'pic' + modal.id"
                  >
                  <my-curtain
                    color-bg="bg-primary"
                  />
                </scroll-parallax>
              </div>

              <div class="text-box mt-3">
                <div class="d-flex justify-content-between">
                  <span class="text-primary-dark">{{ $t(modal.mark) }}</span>
                  <strong class="small">{{ $t(modal.date) }}</strong>
                </div>
                <hr>
                <p class="mt-3">
                  {{ $t(modal.text1) }}
                </p>

                <div v-if="isShowBox">
                  <p>{{ $t(modal.text2) }}</p>
                  <p>{{ $t(modal.text3) }}</p>
                </div>

                <button
                  @click="isShowBox=!isShowBox"
                  class="text-primary-dark bg-transparent mb-3 border-0 p-0"
                >
                  <span v-if="!isShowBox">{{ $t('page.read_more') }}</span>
                  <span v-if="isShowBox">{{ $t('page.hide_text') }}</span>
                </button>
              </div>
            </div>

            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                {{ $t('page.close') }}
              </button>
            </div>
          </div>
        </modal-box>
      </li>
    </ul>
  </div>

</template>

<script>
import {ref} from "vue";
import MyPagination from "@/components/ui/MyPagination";
import ModalBox from "@/components/ModalBox"
import MyCurtain from "@/components/ui/MyCurtain";

export default {
  props: [
    'cards',
    'classes',
    'class_link',
    'perPageValue',
    'limit',
    'classes_col',
    'maxShowButtons'
  ],

  components: { MyPagination, ModalBox, MyCurtain },

  setup(props) {
    const perPage = ref(props.perPageValue)
    const currentPage = ref(1)
    const totalPages = ref(1)
    const maxVisibleButtons = ref(props.maxShowButtons)
    const isShowBox = ref(false)

    const params = ref('down')
    const sortByDateDown = ref((d1, d2) => (d1.id < d2.id) ? 1 : -1)
    const sortByDateUp = ref((d1, d2) => (d1.id > d2.id) ? 1 : -1)

    const filterByNews = ref((elem) => elem.mark === 'page.news')
    const filterByEvent = ref((elem) => elem.mark === 'page.event')

    function onPageChange(page) {
      currentPage.value = page;
    }

    return {
      perPage,
      currentPage,
      totalPages,
      maxVisibleButtons,
      params,
      sortByDateDown,
      sortByDateUp,
      filterByNews,
      filterByEvent,
      isShowBox,
      onPageChange,
      moveUp: () => window.scrollTo(0,0)
    }
  },

  computed: {
    sortedList() {
      switch(this.params){
        case 'down': return this.cards.sort(this.sortByDateDown);
        case 'up': return this.cards.sort(this.sortByDateUp);
        case 'news': return this.cards.filter(this.filterByNews);
        case 'event': return this.cards.filter(this.filterByEvent);
        default: return this.cards;
      }
    },

    cardsComputed() {
      return this.sortedList.slice((this.currentPage - 1) * this.perPage, this.currentPage * this.perPage)
    },

    totalPages() {
      if (this.limit) {
        return this.totalPages = Math.ceil(this.sortedList.slice(-this.limit).length / this.perPage)
      }

      return this.totalPages = Math.ceil(this.sortedList.length / this.perPage)
    }
  }
}

</script>

<style scoped>
.card-body {
  border-radius: 50px 0 50px 0 !important;
  cursor: pointer;
}
.card-body .img-wrap {
  border-radius: 35px 0 35px 0 !important;
}
img {
  border-radius: 0 !important;
}
.link {
  opacity: 0.5;
  color: var(--blue);
  transition: 0.5s;
}
a:hover .link  {
  opacity: 1;
}
a:hover img {
  filter: none !important;
  transform: scale(1.1);
}
.card-date {
  margin-top: 5px;
  font-size: 0.7rem;
}
.card-title {
  -webkit-line-clamp: 2; /* Число отображаемых строк */
  display: -webkit-box; /* Включаем флексбоксы */
  -webkit-box-orient: vertical; /* Вертикальная ориентация */
  overflow: hidden; /* Обрезаем всё за пределами блока */
}
.card-text {
  -webkit-line-clamp: 3; /* Число отображаемых строк */
  display: -webkit-box; /* Включаем флексбоксы */
  -webkit-box-orient: vertical; /* Вертикальная ориентация */
  overflow: hidden; /* Обрезаем всё за пределами блока */
}
</style>
