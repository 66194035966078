<template>
  <div class="current-date-time ms-2 mt-1 mt-sm-0 ms-sm-4 text-white">
    <div class="d-flex text-uppercase mb-0">
      <p class="current-date">{{ nowDate }}</p>
      <p class="mx-1">|</p>
      <p class="current-time">{{ nowTime }}</p>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { computed } from '@vue/runtime-core';

export default {
  setup() {
    const store = useStore()
    return {
      nowDate: computed(() => store.getters.isDate.date)
    }
  },
  // RealTime
  data: () => ({
    date: 1580558031264,
  }),
  computed: {
    nowTime() {
      return (new Date(this.date)).toLocaleTimeString()
    },
  },
  created() {
    this.intervalId = setInterval(() => this.date = Date.now(), 1000);
  },
  beforeDestroy() {
    if (this.intervalId) clearInterval(this.intervalId)
  },
}

</script>

<style scoped>
p {
  font-size: 0.8rem;
  opacity: 0.5;
  margin-bottom: 0;
}
.current-time {
  font-weight: bold;
}
@media (max-width: 576px) {
  .current-date-time {
    position: absolute;
    bottom: -11px;
    left: -7px;
    display: inline-block;
    min-width: 200px;
  }
  p {
    font-size: 0.6rem;
    opacity: 0.2;
  }
}
</style>
