<template>
  <div class="col col-lg-6 m-auto item-video pb-0 px-md-0">
    <div
      class="poster-video position-relative"
      :data-bs-target="idModal"
      data-bs-toggle="modal"
      type="button"
    >
      <my-picture-box
        :src="poster"
      />
      <div
        v-if="text"
        class="text m-2">
        <p class="text-white">{{ $t(text) }}</p>
      </div>
      <div
        id="icon-play-video"
        class="a-center"
      >
        <i class="fa-solid fa-play text-primary"/>
      </div>
    </div>
  </div>
</template>

<script>
import MyPictureBox from "@/components/ui/MyPictureBox";

export default {
  props: ['idModal', 'poster', 'text'],
  components: {
   MyPictureBox
  }
};
</script>

<style scoped>
.poster-video {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  font-size: 5rem;
  transition: 0.3s;
  width: 100%;
}
.poster-video:hover svg {
  transform: scale(1.2);
}
.text {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--gradient-v);
}
p {
  position: absolute;
  z-index: 1;
  left: 0;
  top: 5%;
  width: 100%;
  text-align: center;
  opacity: 1;
  font-size: 2.5rem;
  line-height: 1.3;
}
#icon-play-video {
  border: none;
  margin: 0;
  padding: 0;
  background-color: transparent;
  cursor: pointer;
  opacity: 0.8;
  z-index: 7;
}
#icon-play-video:hover {
  opacity: 1 !important;
}
#icon-play-video svg {
  display: block;
  transition: 0.5s;
}
</style>
