<template>
  <ul
    class="row row-cols-1 row-cols-lg-2 row-cols-xxl-3 border-white border-top border-bottom border-all-0 mb-0"
    :class="classes"
  >
    <li class="col p-0">
      <my-picture-box :src="picture1"/>
    </li>
    <li class="col text-center m-auto">
      <div class="m-auto px-0 py-5 p-md-5">
        <h3 class="mb-0 mb-lg-3">{{ $t(title) }}</h3>
        <my-link
          v-if="href"
          classes="btn-outline-light"
          link_text="page.learn_more"
          :href="href"
        />
        <router-link
          v-if="router_link"
          :to="{ name: router_link, params: { locale: this.$i18n.locale }}"
          :class="class_link"
          class="mt-3 mt-lg-1"
          @click="moveUp"
        >
          {{ $t('page.learn_more') }}
        </router-link>
      </div>
    </li>
    <li class="col p-0">
      <my-picture-box :src="picture2"/>
    </li>
    <li class="col m-auto px-md-0">
      <div class="px-0 pt-4 pb-3 px-md-0 px-lg-5 p-xl-5 p-xxl-4">
        <p class="mb-0">{{ $t(text1) }}</p>
      </div>
    </li>
    <li class="col p-0">
      <my-picture-box :src="picture3"/>
    </li>
    <li class="col m-auto px-md-0">
      <div class="px-0 pt-4 pb-3 px-md-0 px-lg-5 p-xl-5 p-xxl-4">
        <p class="mb-0">{{ $t(text2) }}</p>
      </div>
    </li>
  </ul>
</template>

<script>
import MyPictureBox from "@/components/ui/MyPictureBox";
import MyLink from "@/components/ui/MyLink";

export default {
  components: { MyPictureBox, MyLink },
  props: ['picture1', 'picture2', 'picture3', 'title', 'text1', 'text2', 'href', 'classes', 'class_link',
    'router_link'],
  setup() {
    return {
      moveUp: () => window.scrollTo(0,0)
    }
  }
};
</script>

<style scoped>
</style>
