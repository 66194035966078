<template>
  <header class="container-fluid fixed-top bg-dark">
    <div class="container">
      <nav class="d-flex flex-wrap align-items-center justify-content-between py-3">
        <div class="logo-date d-flex position-relative mt-0 mt-sm-2">
          <router-link
            :to="{ name: 'Home', params: { locale: this.$i18n.locale }}"
            class="navbar-brand"
            type="button"
            @click="moveUp"
          >
            <img
              height="19"
              src="@/assets/img/logo.png"
              style="margin-top: -15px"
              alt="logo"
            />
          </router-link>
          <my-date />
        </div>

        <div class="d-flex">
          <locale-switcher
            classes="m-0 me-4 lang-switcher-out"
          />

          <div
            class="icon text-primary menu-icon position-relative"
            aria-controls="offcanvas"
            data-bs-target="#offcanvas"
            data-bs-toggle="offcanvas"
            type="button"
          >
            <span class="line"></span>
            <span class="a-center">
            <i class="plane-icon fa-solid fa-plane"></i>
          </span>
            <span class="line"></span>
          </div>
        </div>

        <div
          aria-labelledby="offcanvasLabel"
          class="offcanvas offcanvas-end bg-dark"
          id="offcanvas"
          tabindex="-1"
        >
          <div class="offcanvas-header border-bottom border-white">
          <span class="offcanvas-title text-white pt-2 text-uppercase"
                id="offcanvasLabel">
            {{ $t('menu.title') }}
          </span>
            <button
              aria-label="Close"
              class="btn-close btn-close-white"
              data-bs-dismiss="offcanvas"
              type="button"
            />
          </div>
          <div class="offcanvas-body mt-3">
            <button
              class="btn btn-outline-warning"
              data-bs-target="#staticBackdrop"
              data-bs-toggle="modal"
              type="button"
            >
              {{ $t('menu.crm') }}
            </button>

            <ul class="navbar-nav mt-4">
              <li
                class="nav-item"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              >
                <router-link
                  :to="{ name: 'Home', params: { locale: this.$i18n.locale }}"
                  @click="moveUp"
                >
                  {{ $t('page.home') }}
                </router-link>
              </li>
              <li
                class="nav-item"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              >
                <router-link
                  :to="{ name: 'About', params: { locale: this.$i18n.locale }}"
                  @click="moveUp"
                >
                  {{ $t('page.about') }}
                </router-link>
              </li>
              <li
                class="nav-item"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              >
                <router-link
                  :to="{ name: 'History', params: { locale: this.$i18n.locale }}"
                  @click="moveUp"
                >
                  {{ $t('page.history') }}
                </router-link>
              </li>
              <li
                class="nav-item"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              >
                <router-link
                  :to="{ name: 'News', params: { locale: this.$i18n.locale }}"
                  @click="moveUp"
                >
                  {{ $t('page.news') }}
                </router-link>
              </li>
              <li
                class="nav-item"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              >
                <router-link
                  :to="{ name: 'Training', params: { locale: this.$i18n.locale }}"
                  @click="moveUp"
                >
                  {{ $t('page.training') }}
                </router-link>
              </li>
              <li
                class="nav-item"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              >
                <router-link
                  :to="{ name: 'ContactUs', params: { locale: this.$i18n.locale }}"
                  @click="moveUp"
                >
                  {{ $t('page.contact') }}
                </router-link>
              </li>
            </ul>

            <locale-switcher
              classes="mt-3 me-4 lang-switcher-in"
            />

            <span
              class="text-white d-block mt-4"
            >
            {{ $t('menu.social') }}
          </span>
            <ul class="d-flex p-0 mt-3 social-block">
              <li>
                <a
                  href="https://adbbox.com/"
                  target="_blank"
                  class="btn btn-outline-light border-white"
                  data-bs-dismiss="offcanvas"
                >
                  <i class="fa-solid fa-envelope"/>
                </a>
              </li>
              <li>
                <a
                  href="https://adbbox.com/"
                  target="_blank"
                  class="btn btn-outline-light border-white ms-2"
                  data-bs-dismiss="offcanvas"
                >
                  <i class="fa-brands fa-instagram"/>
                </a>
              </li>
              <li>
                <a
                  href="https://adbbox.com/"
                  target="_blank"
                  class="btn btn-outline-light border-white ms-2"
                  data-bs-dismiss="offcanvas"
                >
                  <i class="fa-brands fa-youtube"/>
                </a>
              </li>
              <li>
                <a
                  href="https://adbbox.com/"
                  target="_blank"
                  class="btn btn-outline-light border-white ms-2"
                  data-bs-dismiss="offcanvas"
                >
                  <i class="fa-brands fa-facebook-f"/>
                </a>
              </li>
              <li>
                <a
                  href="https://adbbox.com/"
                  target="_blank"
                  class="btn btn-outline-light border-white ms-2"
                  data-bs-dismiss="offcanvas"
                >
                  <i class="fa-brands fa-twitter"/>
                </a>
              </li>
              <li>
                <a
                  href="https://adbbox.com/"
                  target="_blank"
                  class="btn btn-outline-light border-white ms-2"
                  data-bs-dismiss="offcanvas"
                >
                  <i class="fa-brands fa-linkedin-in"/>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  </header>
</template>

<script>
import LocaleSwitcher from '@/components/LocaleSwitcher';
import MyDate from "@/components/ui/MyDate";
import Home from "@/views/Home";

export default {
  components: { Home, LocaleSwitcher, MyDate},

  setup() {
    return {
      moveUp: () => window.scrollTo(0,0)
    }
  }
}
</script>

<style scoped>
  header {
    background-color: rgb(33 37 41 / 70%) !important;
  }
  .menu-icon .line {
    display: block;
    width: 45px;
    height: 3px;
    background-color: var(--blue);
    border-radius: 4px;
    transition: 0.3s;
  }
  .menu-icon span:nth-child(1) {
    margin: 13px 0 8px 0;
  }
  .menu-icon:hover span:nth-child(1) {
    margin: 0 0 36px 0;
  }
  .menu-icon span:nth-child(2) {
    transition: 0.5s;
    opacity: 0;
  }
  .menu-icon:hover span:nth-child(2) {
    opacity: 1;
  }
  .menu-icon span:nth-child(3) {
    background-color: #ffc107;;
  }
  .nav-item {
    line-height: 2;
  }
  .nav-item a {
    color: gray;
  }
  .nav-item .active {
    color: var(--blue) !important;
  }
  .nav-item:hover a {
    color: var(--bs-white);
  }
  .offcanvas-body .btn-outline-warning:hover {
    color: #000 !important;
  }
  .btn-close {
    opacity: 0.3;
    transition: 0.3s;
  }
  .btn-close:hover {
    opacity: 1;
  }
  .lang-switcher-in {
    display: none;
  }
  .social-block li a:hover {
    color: var(--bs-dark) !important;
  }

  @media (max-width: 1200px) {
    .menu-icon span:nth-child(1) {
      margin: 9px 0 8px 0;
    }
    .menu-icon:hover span:nth-child(1) {
      margin: 0 0 26px 0;
    }
  }
  @media (max-width: 992px) {
    .menu-icon span:nth-child(1) {
      margin: 10px 0 8px 0;
    }
    .menu-icon:hover span:nth-child(1) {
      margin: 0 0 32px 0;
    }
  }
  @media (max-width: 768px) {
    .menu-icon span:nth-child(1) {
      margin: -4px 0 8px 0;
    }
    .menu-icon:hover span:nth-child(1) {
      margin: 0 0 32px 0;
    }
    .lang-switcher-out {
      display: none;
    }
    .lang-switcher-in {
      display: block;
    }
  }
  @media (max-width: 576px) {
    .icon svg.fa-plane {
      font-size: 1rem;
    }
    .menu-icon:hover span:nth-child(1) {
      margin: 0 0 24px 0;
    }
  }
</style>
