<template>
  <div
    id="progressBar"
    class="progress-bar"
  />
</template>

<script>
export default {
 setup() {
   function progressBar() {
     let scroll = document.body.scrollTop || document.documentElement.scrollTop;
     let height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
     let scrolled = scroll / height * 100;

     document.getElementById('progressBar').style.width = scrolled + '%';
   }

   window.addEventListener('scroll', progressBar);
 }
}
</script>

<style scoped>
.progress-bar {
  position: fixed;
  z-index: 99999;
  left: 0;
  bottom: 0;
  width: 0;
  height: 5px;
  border-radius: 5px;
  background: linear-gradient(to right, #f00, #ffa500, #ff0, #008000, #00f, #4b0082, #ee82ee);
}
</style>
