import { createStore } from "vuex";
import homeModule from "@/store/modules/homeModule";
import aboutModule from "@/store/modules/aboutModule";
import historyModule from "@/store/modules/historyModule";
import newsModule from "@/store/modules/newsModule";
import trainingModule from "@/store/modules/trainingModule";
import contactUsModule from "@/store/modules/contactUsModule";
import pc24Module from "@/store/modules/pc24Module";
import pc12Module from "@/store/modules/pc12Module";
import pc21Module from "@/store/modules/pc21Module";
import pc7Module from "@/store/modules/pc7Module";
import { defaultLocale, localeStorageLang } from "@/i18n";

export default createStore({
  state: {
    lang: localeStorageLang || defaultLocale,
  },
  getters: {
    isLang(state) {
      return state.lang;
    },
    isDate(state) {
      const today = new Date();
      const options = {
        weekday: 'short',
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      };
      const locales = (state.lang === 'en') ? 'en-EN' : 'uk-UK'
      const date = today.toLocaleString(locales, options);
      
      return {
        date: (locales === 'en-EN') ? date : date.slice(0,-2),
      }
    },
  },
  mutations: {
    changeLocale(state, payload) {
      state.lang = payload
    }
  },
  modules: {
    homeModule,
    aboutModule,
    historyModule,
    newsModule,
    trainingModule,
    pc24Module,
    pc12Module,
    pc21Module,
    pc7Module,
    contactUsModule
  },
});
