const model = 'news';
const pageModel = 'page_' + model;
const imgModel = model + '/' + model;

export default {
  state: {
    slides: [
      {
        id: 1,
        isActive: true,
        name: 'page.brand',
        text: pageModel + '.slide1_text',
        src: require('@/assets/img/'+ imgModel +'_1.jpg'),
      },
      {
        id: 2,
        isActive: false,
        name: 'page.brand',
        text: pageModel + '.slide2_text',
        src: require('@/assets/img/'+ imgModel +'_2.jpg'),
      },
      {
        id: 3,
        isActive: false,
        name: 'page.brand',
        text: pageModel + '.slide3_text',
        src: require('@/assets/img/'+ imgModel +'_3.jpg'),
      },
    ],
    cards_news: [
      {
        id: 1,
        date: '07.07.2021',
        mark: 'page.news',
        title: pageModel + '.news_title1',
        text1: pageModel + '.news_text1_1',
        text2: pageModel + '.news_text1_2',
        text3: pageModel + '.news_text1_3',
        src: require('@/assets/img/'+ imgModel +'_4.jpg'),
        href: "NewsSingle"
      },
      {
        id: 2,
        date: '30.07.2021',
        mark: 'page.event',
        title: pageModel + '.news_title2',
        text1: pageModel + '.news_text2_1',
        text2: pageModel + '.news_text2_2',
        text3: pageModel + '.news_text2_3',
        src: require('@/assets/img/'+ imgModel +'_5.jpg'),
        href: "NewsSingle"
      },
      {
        id: 3,
        date: '14.09.2021',
        mark: 'page.event',
        title: pageModel + '.news_title3',
        text1: pageModel + '.news_text3_1',
        text2: pageModel + '.news_text3_2',
        text3: pageModel + '.news_text3_3',
        src: require('@/assets/img/'+ imgModel +'_6.jpg'),
        href: "NewsSingle"
      },
      {
        id: 4,
        date: '11.10.2022',
        mark: 'page.news',
        title: pageModel + '.news_title4',
        text1: pageModel + '.news_text4_1',
        text2: pageModel + '.news_text4_2',
        text3: pageModel + '.news_text4_3',
        src: require('@/assets/img/'+ imgModel +'_7.jpg'),
        href: "NewsSingle"
      },
      {
        id: 5,
        date: '14.11.2021',
        mark: 'page.event',
        title: pageModel + '.news_title5',
        text1: pageModel + '.news_text5_1',
        text2: pageModel + '.news_text5_2',
        text3: pageModel + '.news_text5_3',
        src: require('@/assets/img/'+ imgModel +'_8.jpg'),
        href: "NewsSingle"
      },
      {
        id: 6,
        date: '18.01.2022',
        mark: 'page.news',
        title: pageModel + '.news_title6',
        text1: pageModel + '.news_text6_1',
        text2: pageModel + '.news_text6_2',
        text3: pageModel + '.news_text6_3',
        src: require('@/assets/img/'+ imgModel +'_9.jpg'),
        href: "NewsSingle"
      },
      {
        id: 7,
        date: '01.03.2022',
        mark: 'page.event',
        title: pageModel + '.news_title7',
        text1: pageModel + '.news_text7_1',
        text2: pageModel + '.news_text7_2',
        text3: pageModel + '.news_text7_3',
        src: require('@/assets/img/'+ imgModel +'_10.jpg'),
        href: "NewsSingle"
      },
      {
        id: 8,
        date: '11.04.2022',
        mark: 'page.news',
        title: pageModel + '.news_title8',
        text1: pageModel + '.news_text8_1',
        text2: pageModel + '.news_text8_2',
        text3: pageModel + '.news_text8_3',
        src: require('@/assets/img/'+ imgModel +'_11.jpg'),
        href: "NewsSingle"
      },
      {
        id: 9,
        date: '28.04.2022',
        mark: 'page.event',
        title: pageModel + '.news_title9',
        text1: pageModel + '.news_text9_1',
        text2: pageModel + '.news_text9_2',
        text3: pageModel + '.news_text9_3',
        src: require('@/assets/img/'+ imgModel +'_12.jpg'),
        href: "NewsSingle"
      },
      {
        id: 10,
        date: '19.07.2022',
        mark: 'page.news',
        title: pageModel + '.news_title10',
        text1: pageModel + '.news_text10_1',
        text2: pageModel + '.news_text10_2',
        text3: pageModel + '.news_text10_3',
        src: require('@/assets/img/'+ imgModel +'_13.jpg'),
        href: "NewsSingle"
      },
      {
        id: 11,
        date: '23.09.2022',
        mark: 'page.news',
        title: pageModel + '.news_title11',
        text1: pageModel + '.news_text11_1',
        text2: pageModel + '.news_text11_2',
        text3: pageModel + '.news_text11_3',
        src: require('@/assets/img/'+ imgModel +'_14.jpg'),
        href: "NewsSingle"
      },
      {
        id: 12,
        date: '13.10.2022',
        mark: 'page.event',
        title: pageModel + '.news_title12',
        text1: pageModel + '.news_text12_1',
        text2: pageModel + '.news_text12_2',
        text3: pageModel + '.news_text12_3',
        src: require('@/assets/img/'+ imgModel +'_15.jpg'),
        href: "NewsSingle"
      },
      {
        id: 13,
        date: '17.10.2022',
        mark: 'page.news',
        title: pageModel + '.news_title13',
        text1: pageModel + '.news_text13_1',
        text2: pageModel + '.news_text13_2',
        text3: pageModel + '.news_text13_3',
        src: require('@/assets/img/'+ imgModel +'_16.jpg'),
        href: "NewsSingle"
      },
      {
        id: 14,
        date: '21.10.2022',
        mark: 'page.event',
        title: pageModel + '.news_title14',
        text1: pageModel + '.news_text14_1',
        text2: pageModel + '.news_text14_2',
        text3: pageModel + '.news_text14_3',
        src: require('@/assets/img/'+ imgModel +'_17.jpg'),
        href: "NewsSingle"
      },
      {
        id: 15,
        date: '02.11.2022',
        mark: 'page.news',
        title: pageModel + '.news_title15',
        text1: pageModel + '.news_text15_1',
        text2: pageModel + '.news_text15_2',
        text3: pageModel + '.news_text15_3',
        src: require('@/assets/img/'+ imgModel +'_18.jpg'),
        href: "NewsSingle"
      },
    ],
    
    img1: require('@/assets/img/'+ imgModel +'_1.jpg'),
    
    text1: 'page.news',
    
    text2: pageModel + '.sec2_title1',
    text3: pageModel + '.sec2_text1',
    
    text4: pageModel + '.sec3_bigtitle1',
    text5: pageModel + '.sec3_bigtitle2',
    
    /*text20: pageModel + '.',*/
  }
}
