<template>
  <transition name="fade">
    <div v-if="show" :class="{ active: isActive,'preloader bg-primary':'preloader bg-primary' }">
      <div class="text-center">
        <div class="redesign">
          <p>w</p>
          <p>e</p>
          <p>b</p>
          <p>s</p>
          <p>i</p>
          <p>t</p>
          <p>e</p>
          <p> </p>
          <p>r</p>
          <p>e</p>
          <p>d</p>
          <p>s</p>
          <p>i</p>
          <p>g</p>
          <p>n</p>
        </div>
        <h4>Pilatus Aircraft Ltd</h4>
        <div class="line"></div>
        <div class="dev">
          <p>d</p>
          <p>e</p>
          <p>v</p>
          <p>e</p>
          <p>l</p>
          <p>o</p>
          <p>p</p>
          <p>m</p>
          <p>e</p>
          <p>n</p>
          <p>t</p>
          <p> </p>
          <p>b</p>
          <p>y</p>
          <p> </p>
          <p>a</p>
          <p>d</p>
          <p>b</p>
          <p>b</p>
          <p>o</p>
          <p>x</p>
          <p>.</p>
          <p>c</p>
          <p>o</p>
          <p>m</p>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { ref } from 'vue'

export default {
  setup() {
    const show = ref(true)
    const isActive=ref(true)

    const showToggle = () => {
      setTimeout(() => {
        show.value = false
        isActive.value = false
      }, 1500)
    }

    return {
      show, isActive, showToggle
    }
  },

  mounted(){
    if(Boolean(this.show)) this.showToggle()
  }
}
</script>

<style scoped>
.preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  color: white;
  z-index: 999999;
  display: flex;
  justify-content: center;
  align-items: center;
}
h4 {
  text-transform: uppercase;
  font-weight: bold;
  line-height: 0.8;
}
.redesign {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: -10px;
  opacity: 0.5;
}
.redesign p {
  font-size: 0.4rem;
}
.dev {
  margin-top: 5px;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
p {
  text-transform: uppercase;
  font-size: 0.5rem;
}
.line {
  position: relative;
  width: 100%;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.1);
}
.line::after {
  content: "";
  display: block;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  height: 1px;
  background-color: white;
  animation: drawLine 1.6s ease-in-out forwards;
}
@keyframes drawLine {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
.fade-enter-active, .fade-leave-active {
  transition: opacity 2s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
