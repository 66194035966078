<template>
  <ul class="row text-dark mt-5 mt-md-5 px-0 py-3 pt-lg-5 mb-0">
    <li
      v-for="card in cards"
      :key="card.id"
      class="col-12 col-lg-5 m-auto mb-5 p-0 card border-0">
      <img
        class="bw w-100 h-100"
        :src="card.src"
        :alt="'list-cards-models' + card.id"
      />
      <div class="card-body bg-light pt-0">
        <span class="text-uppercase card-title d-inline-block px-2 py-1 text-bg-primary">{{ $t( card.name ) }}</span>
        <p class="card-text mt-3">{{ $t( card.text ) }}</p>
        <router-link
          v-if="card.href"
          :to="{ name: card.href, params: { locale: this.$i18n.locale }}"
          class="btn btn-outline-dark"
          @click="moveUp"
        >
          {{ $t('page.learn_more')}}
        </router-link>
      </div>
    </li>
  </ul>
</template>

<script>
export default {
  props: ['cards'],

  setup() {
    return {
      moveUp: () => window.scrollTo(0,0)
    }
  }
}
</script>

<style scoped>
.card {
  margin-top: 0 !important;
  border-radius: 15% 0 15% 0 !important;
  transition: 0.5s;
  overflow: hidden;
}
.card:hover .bw {
  filter: none;
}
.card:hover {
  box-shadow: 15px 20px 15px rgba(0, 0, 0, 0.05);
}
.card-text {
  -webkit-line-clamp: 2; /* Число отображаемых строк */
  display: -webkit-box; /* Включаем флексбоксы */
  -webkit-box-orient: vertical; /* Вертикальная ориентация */
  overflow: hidden; /* Обрезаем всё за пределами блока */
}

@media (max-width: 756px) {
  .card:hover {
    box-shadow: 10px 15px 10px rgba(0, 0, 0, 0.07);
  }
}
</style>
