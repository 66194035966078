const model = 'pc24';
const pageModel = 'page_' + model;
const imgModel = model + '/' + model;

export default {
  state: {
    pc_slides: [
      {
        id: 1,
        isActive: true,
        name: 'page.model1',
        text: pageModel + '.slide1_text',
        src: require('@/assets/img/'+ imgModel +'_17.jpg')
      },
      {
        id: 2,
        isActive: false,
        name: 'page.model1',
        text: pageModel + '.slide2_text',
        src: require('@/assets/img/'+ imgModel +'_18.jpg')
      },
      {
        id: 3,
        isActive: false,
        name: 'page.model1',
        text: pageModel + '.slide3_text',
        src: require('@/assets/img/'+ imgModel +'_19.jpg')
      }
    ],
    list_model1: [
      {
        id: 1,
        icon: require('@/assets/icons/chair.svg'),
        title: 'page.i_max_passengers',
        desc: pageModel + '.sec3_i_desc1'
      },
      {
        id: 2,
        icon: require('@/assets/icons/payload.svg'),
        title: "page.i_max_payload",
        desc: pageModel + '.sec3_i_desc2'
      },
      {
        id: 3,
        icon: require('@/assets/icons/cloud.svg'),
        title: "page.i_max_altitude",
        desc: pageModel + '.sec3_i_desc3'
      }
    ],
    list_model2: [
      {
        id: 1,
        icon: require('@/assets/icons/wheel.svg'),
        title: 'page.i_kind_of_operation',
        desc: pageModel + '.sec3_i_desc4'
      },
      {
        id: 2,
        icon: require('@/assets/icons/transmission.svg'),
        title: "page.i_max_take-off_thrust",
        desc: pageModel + '.sec3_i_desc5'
      },
      {
        id: 3,
        icon: require('@/assets/icons/speed.svg'),
        title: "page.i_stall_speed",
        desc: pageModel + '.sec3_i_desc6'
      }
    ],
    list_data: [
      {
        id: 1,
        icon: 'page.i_max_cruise_speed',
        text: pageModel + '.sec4_dig1'
      },
      {
        id: 2,
        icon: 'page.i_max_range_4passengers',
        text: pageModel + '.sec4_dig2'
      },
      {
        id: 3,
        icon: 'page.i_max_range_6passengers',
        text: pageModel + '.sec4_dig3'
      },
      {
        id: 4,
        icon: 'page.i_max_altitude',
        text: pageModel + '.sec4_dig4'
      },
      {
        id: 5,
        icon: 'page.i_take_off_balanced_field_length',
        text: pageModel + '.sec4_dig5'
      },
      {
        id: 6,
        icon: 'page.i_rate_of_climb',
        text: pageModel + '.sec4_dig6'
      },
      {
        id: 7,
        icon: 'page.i_max_ramp',
        text: pageModel + '.sec4_dig7'
      },
      {
        id: 8,
        icon: 'page.i_max_take_off',
        text: pageModel + '.sec4_dig8'
      },
    ],
    list_reasons: [
      {
        id: 1,
        title: pageModel + '.sec5_title1',
        desc: pageModel + '.sec5_text1'
      },
      {
        id: 2,
        title: pageModel + '.sec5_title2',
        desc: pageModel + '.sec5_text2'
      },
      {
        id: 3,
        title: pageModel + '.sec5_title3',
        desc: pageModel + '.sec5_text3'
      },
      {
        id: 4,
        title: pageModel + '.sec5_title4',
        desc: pageModel + '.sec5_text4'
      },
      {
        id: 5,
        title: pageModel + '.sec5_title5',
        desc: pageModel + '.sec5_text5'
      },
      {
        id: 6,
        title: pageModel + '.sec5_title6',
        desc: pageModel + '.sec5_text6'
      },
      {
        id: 7,
        title: pageModel + '.sec5_title7',
        desc: pageModel + '.sec5_text7'
      },
      {
        id: 8,
        title: pageModel + '.sec5_title8',
        desc: pageModel + '.sec5_text8'
      },
      {
        id: 9,
        title: pageModel + '.sec5_title9',
        desc: pageModel + '.sec5_text9'
      },
      {
        id: 10,
        title: pageModel + '.sec5_title10',
        desc: pageModel + '.sec5_text10'
      }
    ],
    list_tutorials: [
      {
        id: 1,
        isActive: true,
        src: require('@/assets/img/'+ imgModel +'_20.jpg'),
        name: pageModel + '.modal_text1',
      },
      {
        id: 2,
        isActive: false,
        src: require('@/assets/img/'+ imgModel +'_21.jpg'),
        name: pageModel + '.modal_text2',
      },
      {
        id: 3,
        isActive: false,
        src: require('@/assets/img/'+ imgModel +'_22.jpg'),
        name: pageModel + '.modal_text3',
      },
      {
        id: 4,
        isActive: false,
        src: require('@/assets/img/'+ imgModel +'_23.jpg'),
        name: pageModel + '.modal_text4',
      },
      {
        id: 5,
        isActive: false,
        src: require('@/assets/img/'+ imgModel +'_24.jpg'),
        name: pageModel + '.modal_text5',
      }
    ],
    list_modal_tutorials: [
      {
        id: 1,
        frameSrc: "https://youtu.be/wvKehRAUAVo",
        height_video: "280px"
      },
      {
        id: 2,
        frameSrc: "https://youtu.be/PCE5Fil0QWE",
        height_video: "280px"
      },
      {
        id: 3,
        frameSrc: "https://youtu.be/KB1A7x4Iv6s",
        height_video: "280px"
      },
      {
        id: 4,
        frameSrc: "https://youtu.be/Oe3yHmELFn4",
        height_video: "280px"
      },
      {
        id: 5,
        frameSrc: "https://youtu.be/K4L0i4hcCjs",
        height_video: "280px"
      }
    ],
    list_docs: [
      {
        id: 1,
        href: "https://www.pilatus-aircraft.com/data/document/Pilatus-Aircraft-Ltd-PC-24-AirAmbulance.pdf",
        src: require('@/assets/img/books/Pilatus-Aircraft-Ltd-PC-24-AirAmbulance.jpg'),
      },
      {
        id: 2,
        href: "https://www.pilatus-aircraft.com/data/document/Pilatus-Aircraft-Ltd-PC-24-CrystalCare.pdf",
        src: require('@/assets/img/books/Pilatus-Aircraft-Ltd-PC-24-CrystalCare-preview_.jpg'),
      },
      {
        id: 3,
        href: "https://www.pilatus-aircraft.com/data/document/Pilatus-Aircraft-Ltd-PC-24-Brochure.pdf",
        src: require('@/assets/img/books/Pilatus-Aircraft-Ltd-PC-24-Brochure-Preview.jpg'),
      },
      {
        id: 4,
        href: "https://www.pilatus-aircraft.com/data/document/Pilatus-Aircraft-Ltd-PC-24-Factsheet.pdf",
        src: require('@/assets/img/books/Pilatus-Aircraft-Ltd-PC-24-Factsheet.jpg'),
      }
    ],
  
    img1: require('@/assets/img/'+ imgModel +'_1.jpg'),
    img2: require('@/assets/img/'+ imgModel +'_2.jpg'),
    img3: require('@/assets/img/'+ imgModel +'_3.jpg'),
    img4: require('@/assets/img/'+ imgModel +'_4.jpg'),
    
    img5: require('@/assets/img/'+ imgModel +'_5.jpg'),
    img6: require('@/assets/img/'+ imgModel +'_6.jpg'),
    img7: require('@/assets/img/'+ imgModel +'_7.jpg'),
    img8: require('@/assets/img/'+ imgModel +'_8.jpg'),
  
    img9: require('@/assets/img/'+ imgModel +'_9.jpg'),
  
    img10: require('@/assets/img/'+ imgModel +'_3.jpg'),
    img11: require('@/assets/img/'+ imgModel +'_10.jpg'),
    img12: require('@/assets/img/'+ imgModel +'_11.jpg'),
    img13: require('@/assets/img/'+ imgModel +'_12.jpg'),
  
    img14: require('@/assets/img/board/pilatus_pc24_size-comparator_01.png'),
    img15: require('@/assets/img/board/pilatus_pc24-size-comparator_02.png'),
    img16: require('@/assets/img/board/pilatus_pc24-size-comparator_03.png'),
    img17: require('@/assets/img/board/pilatus_pc24_cabine-comparator_01.png'),
    img18: require('@/assets/img/board/pilatus_pc24_cabine-comparator_02.png'),
  
    img19: require('@/assets/img/'+ imgModel +'_13.jpg'),
    img20: require('@/assets/img/'+ imgModel +'_14.jpg'),
    img21: require('@/assets/img/'+ imgModel +'_15.jpg'),
    img22: require('@/assets/img/'+ imgModel +'_16.jpg'),
  
    subtitle1: 'page.cabin',
    subtitle2: 'page.cabin',
    subtitle3: 'page.cockpit',
    subtitle4: 'page.cockpit',
    subtitle5: 'page.feeling',
    subtitle6: 'page.technicals',
  
    text1: 'page.model1',
    
    text2: pageModel + '.sec2_title1',
    text3: pageModel + '.sec2_text1',
    text4: pageModel + '.sec2_title2',
    text5: pageModel + '.sec2_text2',
    text6: pageModel + '.sec2_text3',
    text7: pageModel + '.sec2_title3',
    text8: pageModel + '.sec2_text4',
    text9: pageModel + '.sec2_bigtitle1',
    
    text10: pageModel + '.sec3_title1',
    text11: pageModel + '.sec3_text1',
    text12: pageModel + '.sec3_title2',
    text13: pageModel + '.sec3_text2',
    text14: pageModel + '.sec3_title3',
    text15: pageModel + '.sec3_text3',
    text16: pageModel + '.sec3_title4',
    text17: pageModel + '.sec3_text4',
  
    text18: pageModel + '.sec4_title1',
    text19: pageModel + '.sec4_text1',
  
    text20: pageModel + '.sec5_title',
  }
}
