<template>
  <ul
    class="lang"
    id="lang"
    :class="classes"
  >
    <li v-for="lang in locales"
        :key="lang"
        class="btn btn-outline-primary me-2"
        :class="{active: (currentLang === lang)}"
        @click="(currentLang !== lang) ? switchLocale() : null">
      {{ lang }}
    </li>
  </ul>
</template>

<script>
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { ref } from "vue";
import router from "@/router/router";

export default {
  props: ['classes'],
  setup() {
    const store = useStore();
    const currentLang = ref(store.getters.isLang);
    const { locale } = useI18n({ useScope: 'global' });

    const switchLocale = () => {
      locale.value = locale.value === 'en' ? 'uk' : 'en';
      currentLang.value = locale.value;
      localStorage.setItem('lang', locale.value);
      store.commit('changeLocale', locale.value);
      // Переключает locale в url
      const to = router.resolve({params: { locale: locale.value }})
      router.push(to)
    }

    return {
      locales: process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(','),
      currentLang,
      switchLocale,
    }
  }
}
</script>

<style scoped>
  .btn:hover {
    background-color: rgba(13, 110, 253, 0.2) !important;
  }
  .btn.active:hover {
    background-color: rgba(13, 110, 253, 1) !important;
  }
</style>
