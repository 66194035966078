import { createRouter, createWebHistory } from "vue-router";
import { defaultLocale, i18n, localeStorageLang } from "@/i18n";

function load(component) {
  return () => import(`@/views/${component}.vue`);
}

const routes = [
  {
    path: `/:locale`,
    component: {
      template: "<router-view></router-view>",
    },
    
    beforeEnter: (to, from, next) => {
      const locale = to.params.locale;
      const supported_locales =
        process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(",");

      if (!supported_locales.includes(locale)) {
        return next(process.env.VUE_APP_I18N_LOCALE);
      }

      if (i18n.locale !== locale) {
        i18n.locale = locale;
      }

      return next();
    },
    
    children: [
      {
        path: "",
        name: "Home",
        component: load("Home"),
      },
      {
        path: "about",
        name: "About",
        component: load("About"),
      },
      {
        path: "history",
        name: "History",
        component: load("History"),
      },
      {
        path: "news",
        name: "News",
        component: load("News"),
      },
      {
        path: "training",
        name: "Training",
        component: load("Training"),
      },
      {
        path: "pc24",
        name: "PC24",
        component: load("PC24"),
      },
      {
        path: "pc12",
        name: "PC12",
        component: load("PC12"),
      },
      {
        path: "pc21",
        name: "PC21",
        component: load("PC21"),
      },
      {
        path: "pc7",
        name: "PC7",
        component: load("PC7"),
      },
      {
        path: "contacts",
        name: "ContactUs",
        component: load("ContactUs"),
      },
    ],
  },
  {
    path: "/:pathMatch(.*)*",
    redirect() {
      return localeStorageLang || defaultLocale;
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
