const model = 'home';
const pageModel = 'page_' + model;
const imgModel = model + '/' + model;

export default {
  state: {
    slides: [
      {
        id: 1,
        isActive: true,
        name: 'page.model1',
        text: pageModel + '.slide1_text',
        src: require('@/assets/img/'+ imgModel +'_1.jpg'),
        href: "PC24"
      },
      {
        id: 2,
        isActive: false,
        name: 'page.model2',
        text: pageModel + '.slide2_text',
        src: require('@/assets/img/'+ imgModel +'_2.jpg'),
        href: "PC12"
      },
      {
        id: 3,
        isActive: false,
        name: 'page.model3',
        text: pageModel + '.slide3_text',
        src: require('@/assets/img/'+ imgModel +'_3.jpg'),
        href: "PC21"
      },
      {
        id: 4,
        isActive: false,
        name: 'page.model4',
        text: pageModel + '.slide4_text',
        src: require('@/assets/img/'+ imgModel +'_4.jpg'),
        href: "PC7"
      },
    ],
    cards_models: [
      {
        id: 1,
        name: 'page.model1',
        text: pageModel + '.sec3_card1_text',
        src: require('@/assets/img/'+ imgModel +'_5.jpg'),
        href: "PC24"
      },
      {
        id: 2,
        name: 'page.model2',
        text: pageModel + '.sec3_card2_text',
        src: require('@/assets/img/'+ imgModel +'_6.jpg'),
        href: "PC12"
      },
      {
        id: 3,
        name: 'page.model3',
        text: pageModel + '.sec3_card3_text',
        src: require('@/assets/img/'+ imgModel +'_7.jpg'),
        href: "PC21"
      },
      {
        id: 4,
        name: 'page.model4',
        text: pageModel + '.sec3_card4_text',
        src: require('@/assets/img/'+ imgModel +'_8.jpg'),
        href: "PC7"
      },
    ],
    
    list_model_box: [
      {
        id: 1,
        icon: 'fa-sharp fa-solid fa-house',
        desc: 'Homepage',
        src: require('@/assets/img/'+ imgModel +'_5_min.jpg'),
        href: "Home"
      },
      {
        id: 2,
        name: 'PC-24',
        desc: 'PC-24 page',
        src: require('@/assets/img/'+ imgModel +'_1_min.jpg'),
        href: "PC24"
      },
      {
        id: 3,
        name: 'PC-12',
        desc: 'PC-12 page',
        src: require('@/assets/img/'+ imgModel +'_2_min.jpg'),
        href: "PC12"
      },
      {
        id: 4,
        name: 'PC-21',
        desc: 'PC-21 page',
        src: require('@/assets/img/'+ imgModel +'_3_min.jpg'),
        href: "PC21"
      },
      {
        id: 5,
        name: 'PC-7',
        desc: 'PC-7 page',
        src: require('@/assets/img/'+ imgModel +'_4_min.jpg'),
        href: "PC7"
      },
    ],
  
    img1: require('@/assets/img/'+ imgModel +'_10.jpg'),
    img2: require('@/assets/img/'+ imgModel +'_11.jpg'),
    
    img3: require('@/assets/img/'+ imgModel +'_12.jpg'),
    img4: require('@/assets/img/'+ imgModel +'_13.jpg'),
    img5: require('@/assets/img/'+ imgModel +'_14.jpg'),
    
    img6: require('@/assets/img/'+ imgModel +'_15.jpg'),
  
    img7: require('@/assets/img/'+ imgModel +'_16.jpg'),
    img8: require('@/assets/img/'+ imgModel +'_17.jpg'),
    img9: require('@/assets/img/'+ imgModel +'_18.jpg'),
    
    img10: require('@/assets/img/'+ imgModel +'_19.jpg'),
    img11: require('@/assets/img/'+ imgModel +'_20.jpg'),
  
    text1: 'page.home',
  
    text2: pageModel + '.sec2_title1',
    text3: pageModel + '.sec2_text1',
    text4: pageModel + '.sec2_text2',
    text5: pageModel + '.sec2_title2',
    text6: pageModel + '.sec2_text3',
    text7: pageModel + '.sec2_text4',
  
    text8: pageModel + '.sec3_bigtitle1',
    text9: pageModel + '.sec3_bigtitle2',
  
    text10: pageModel + '.sec4_title1',
    text11: pageModel + '.sec4_text1_1',
    text12: pageModel + '.sec4_text1_2',
    text13: pageModel + '.sec4_title2',
    text14: pageModel + '.sec4_text2_1',
    text15: pageModel + '.sec4_text2_2',
    text16: pageModel + '.sec4_text2_3',
    text17: pageModel + '.sec4_title3',
    text18: pageModel + '.sec4_text3_1',
    text19: pageModel + '.sec4_text3_2',
  
    text20: pageModel + '.sec4_title4',
    text21: pageModel + '.sec4_text4_1',
    text22: pageModel + '.sec4_text4_2',
    
    text23: pageModel + '.sec4_title5',
    text24: pageModel + '.sec4_text5_1',
    text25: pageModel + '.sec4_text5_2',
  
    text26: pageModel + '.sec4_title6',
    text27: pageModel + '.sec4_text6_1',
    text28: pageModel + '.sec4_text6_2',
  
    text29: pageModel + '.sec5_bigtitle1',
    
    text30: pageModel + '.sec5_title1',
    text31: pageModel + '.sec5_text1',
  
    text32: pageModel + '.sec5_bigtitle2',
    
    text33: pageModel + '.sec6_title1',
    text34: pageModel + '.sec6_text1_1',
    text35: pageModel + '.sec6_text1_2',
    text36: pageModel + '.sec6_title2',
    text37: pageModel + '.sec6_text2_1',
    text38: pageModel + '.sec6_text2_2',
    text39: pageModel + '.sec6_bigtitle1',
  
    text40: pageModel + '.sec7_title1',
    text41: pageModel + '.sec7_text1_1',
    
    text42: pageModel + '.sec7_title2',
    text43: pageModel + '.sec7_text2_1',
    text44: pageModel + '.sec7_text2_2',
  
    text45: pageModel + '.sec7_title3',
    text46: pageModel + '.sec7_text3_1',
    
    /*text20: pageModel + '.',*/
  }
}
