<template>
  <div
    :id="idModal"
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    tabindex="-1"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5
            class="modal-title"
            id="modalVideoBoxLabel"
          >
            Pilatus Aircraft Ltd
          </h5>
          <button
            class="btn-close text-white"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="pauseVideo">
          </button>
        </div>

        <div class="modal-body p-0 m-0 h-100">
          <you-tube
            :src="frameSrc"
            @ready="onReady"
            ref="youtube"
            width="100%"
            :height="heightVideo"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import YouTube from 'vue3-youtube'

export default {
  props: ['idModal', 'frameSrc', 'heightVideo'],
  methods: {
    onReady() {
      this.$refs.youtube.pauseVideo()
    },
    pauseVideo() {
      this.$refs.youtube.stopVideo()
    }
  },
  components: { YouTube }
};
</script>

<style scoped>

</style>
