<template>
  <footer class="py-3 bg-black">
    <div class="container">
      <div class="container-fluid">
        <div class="row row-cols-1 row-cols-lg-3">
          <div class="col logo mt-1">
          <span class="text-light m-0">
            {{ $t('page.brand') }}
          </span>
            <img
              :src="require('@/assets/img/craftedinswitzerland_footer.svg')"
              alt="brand-made-from"
            />
          </div>
          <div class="col">
            <div class="d-flex justify-content-center align-items-center h-100">
              <a href="https://adbbox.com"
                 target="_blank"
                 class="text-secondary development"
              >
                © 2022 | Development by ADBBOX.COM
              </a>
            </div>
          </div>
          <div class="col social-box">
            <p class="text-secondary">{{ $t('page.contact') }}</p>
            <div class="social-icons mt-1">
              <a href="https://adbbox.com/" target="_blank" class="btn btn-outline-light border-white">
                <i class="fa-solid fa-envelope"></i>
              </a>
              <a href="https://adbbox.com/" target="_blank" class="btn btn-outline-light border-white ms-2">
                <i class="fa-brands fa-instagram"></i>
              </a>
              <a href="https://adbbox.com/" target="_blank" class="btn btn-outline-light border-white ms-2">
                <i class="fa-brands fa-youtube"></i>
              </a>
              <a href="https://adbbox.com/" target="_blank" class="btn btn-outline-light border-white ms-2">
                <i class="fa-brands fa-facebook-f"></i>
              </a>
              <a href="https://adbbox.com/" target="_blank" class="btn btn-outline-light border-white ms-2">
                <i class="fa-brands fa-twitter"></i>
              </a>
              <a href="https://adbbox.com/" target="_blank" class="btn btn-outline-light border-white ms-2">
                <i class="fa-brands fa-linkedin-in"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {};
</script>

<style scoped>
footer {
  margin-top: -18px;
}
.development {
  text-decoration: none !important;
  display: block;
  text-transform: uppercase;
  font-size: 0.7rem;
  margin: 2px 0 0;
}
.development:hover {
  color: white !important;
}
span {
  display: block;
  font-size: 1.8rem;
  line-height: 0.9;
}
p {
  text-transform: uppercase;
  margin: 0;
  text-align: end;
  font-size: 0.5rem;
}
img {
  height: 10px;
}
.social-box {
  margin-top: -4px;
}
.social-icons {
  display: flex;
  justify-content: end;
  margin-top: -3px;
}
.social-icons a:hover svg {
  color: var(--bs-dark) !important;
}

@media (max-width: 992px) {
  span, a, .text-secondary {
    text-align: center !important;
  }
  img {
    display: block;
    margin: 10px auto;
    height: 11px;
  }
  .development {
    margin-bottom: 10px;
  }
  .social-icons {
    justify-content: center;
  }
}
</style>
