<template>
  <main>
    <section-group
      id="offer"
      classes="offer mt-4 mt-sm-2">
      <div class="container-fluid">
        <div class="row">
          <big-slider
            :slides="store.slides"
            controlTargetId="carouselIndicators"
            color-curtain-bg="bg-dark"
          />
          <my-title-page
            :text="store.text1"
            classes=""
          />
        </div>
      </div>
    </section-group>

    <section-group
      id="intro"
      classes="intro text-secondary pt-0 pt-lg-5"
    >
      <div class="container-fluid px-0">
        <div class="row justify-content-end">
          <div class="col col-lg-6 border-start border-white border-all-0">
            <div class="px-0 pt-4 p-lg-5 pb-lg-4">
              <my-text-box
                :title="store.text2"
                :text1="store.text3"
                :text2="store.text4"
              />
            </div>
            <div class="px-0 py-4 p-lg-5 pt-lg-0">
              <my-text-box
                :title="store.text5"
                :text1="store.text6"
                :text2="store.text7"
                classes="btn btn-outline-primary mt-2"
                router_link="About"
              />
            </div>
          </div>
        </div>
      </div>
    </section-group>

    <section-group
      id="models"
      classes="models bg-white text-dark py-0">
      <div class="container-fluid">
        <my-big-title
          :text="store.text8"
        />

        <list-cards-models
          :cards="store.cards_models"
        />

        <my-brand-made-img
          classes="row mb-2 mb-lg-4 mt-0"
        />

        <my-big-title
          :text="store.text9"
          classes="text-primary"
        />
      </div>
    </section-group>

    <section-group
      id="missions"
      classes="missions bg-primary text-white"
    >
      <div class="container-fluid">
        <div class="row flex-column">
          <div
            class="col col-lg-6 align-self-auto align-self-lg-end border-start border-white p-lg-5 px-md-0 border-all-0">
            <my-text-box
              :title="store.text10"
              :text1="store.text11"
              :text2="store.text12"
            />
          </div>
          <div class="col col-lg-6 px-md-0 align-self-auto align-self-lg-center">
            <my-picture-box
              :src="store.img1"
            />
          </div>
          <div
            class="col col-lg-6 mt-4 mt-lg-0 align-self-auto align-self-lg-end border-start border-white p-lg-5 px-md-0 border-lt-0 border-all-0 mb-lg-0 mb-3">
            <my-text-box
              :title="store.text13"
              :text1="store.text14"
              :text2="store.text15"
              :text3="store.text16"
              classes="btn btn-outline-light"
              link_text="page.learn_more"
              router_link="History"
            />
          </div>
          <div class="col col-lg-6 mt-4 mt-lg-0 align-self-auto align-self-lg-center px-md-0 mb-lg-0 mb-3">
            <my-picture-box :src="store.img2"/>
          </div>
          <div
            class="col col-lg-6 mt-3 mt-lg-0 align-self-auto align-self-lg-end border-start border-white px-md-0 p-lg-5 border-all-0">
            <my-text-box
              :title="store.text17"
              :text1="store.text18"
              :text2="store.text19"
            />
          </div>
        </div>

        <list-six-items
          classes="mt-3 mt-lg-0"
          :picture1="store.img3"
          :picture2="store.img4"
          :picture3="store.img5"
          :title="store.text20"
          :text1="store.text21"
          :text2="store.text22"
          class_link="btn btn-outline-light"
          router_link="History"
        />

        <div class="row flex-column">
          <div class="col col-lg-6 align-self-auto align-self-lg-end border-start border-white px-md-0 p-lg-5 border-all-0">
            <my-text-box
              :title="store.text23"
              :text1="store.text24"
              :text2="store.text25"
            />
          </div>
        </div>

        <div class="row flex-column mt-4 mt-lg-0">
          <my-video-switcher
            id-modal="#modalVideoBox"
            :poster="store.img11"
          />
        </div>

        <div class="row flex-column mt-5 mt-lg-5">
          <div class="col col-lg-5 safety-box px-md-0">
            <my-text-box
              :title="store.text26"
              :text1="store.text27"
              :text2="store.text28"
              classes="btn btn-outline-light"
              link_text="page.learn_more"
              router_link="History"
            />
          </div>
        </div>
      </div>
    </section-group>

    <section-group
      id="news"
      classes="news bg-white py-0 text-dark"
    >
      <div class="container-fluid">
        <my-big-title
          :text="store.text29"
          classes="text-primary"
        />

        <div class="row flex-column mt-5">
          <div class="col col-lg-6 align-self-end border-start border-grey p-lg-5 border-all-0">
            <my-text-box
              :title="store.text30"
              :text1="store.text31"
              classes="btn btn-outline-dark"
              link_text="page.learn_more"
              router_link="News"
            />
          </div>
        </div>

        <list-cards-news
          :cards="storeNews.cards_news"
          classes="mt-5"
          class_link="text-blue"
          per-page-value="6"
          limit="12"
          maxShowButtons="2"
          classes_col="col-12 col-lg-6 col-xl-4 px-0 py-4 p-lg-3 p-xl-4"
        />

        <my-big-title
          :text="store.text32"
          classes="text-light mt-5"/>
      </div>
    </section-group>

    <section-group
      id="environment"
      classes="environment bg-light pb-0"
    >
      <div class="container-fluid">
        <div class="row flex-column">
          <div class="col col-lg-6 align-self-end border-start border-grey border-all-0 p-lg-5">
            <my-text-box
              :title="store.text33"
              :text1="store.text34"
              :text2="store.text35"
            />
          </div>
        </div>

        <div class="row flex-column">
          <div class="col col-lg-6 align-self-auto align-self-lg-center">
            <my-picture-box
              classes="bg-dark"
              :src="store.img10"
            />
          </div>
        </div>

        <div class="row flex-column">
          <div class="col col-lg-6 mt-4 mt-lg-0 align-self-end border-start border-grey border-all-0 p-lg-5">
            <my-text-box
              :title="store.text36"
              :text1="store.text37"
              :text2="store.text38"
              classes="btn btn-outline-dark"
              link_text="page.learn_more"
              router_link="About"
            />
          </div>
        </div>

        <my-brand-made-img
          classes="row mb-2 mb-lg-4 mt-5"
        />

        <my-big-title
          :text="store.text39"
          classes="text-primary-dark"
        />
      </div>
    </section-group>

    <section-group
      id="visit"
      classes="visit bg-primary-dark text-light"
    >
      <div class="container-fluid">
        <div class="row flex-column">
          <div class="col col-lg-6 align-self-end border-start border-white border-all-0 p-lg-5">
            <my-text-box
              :title="store.text40"
              :text1="store.text41"
            />
          </div>
        </div>

        <list-six-items
          :picture1="store.img7"
          :picture2="store.img8"
          :picture3="store.img9"
          :title="store.text42"
          :text1="store.text43"
          :text2="store.text44"
          class_link="btn btn-outline-light dark-blue"
          router_link="ContactUs"
        />

        <div class="row flex-column">
          <div class="col col-lg-6 align-self-end border-start border-white border-all-0 p-lg-5">
            <my-text-box
              :title="store.text45"
              :text1="store.text46"
            />
          </div>
        </div>
      </div>
    </section-group>
  </main>

  <modal-video-box
    id-modal="modalVideoBox"
    frameSrc="https://youtu.be/M-XjdIVpku4"
    height-video="280px"
  />
</template>

<script setup>
import { useStore } from "vuex";
import BigSlider from '@/components/BigSlider';
import MyTitlePage from "@/components/ui/MyTitlePage";
import MyBigTitle from "@/components/ui/MyBigTitle";
import MyBrandMadeImg from "@/components/ui/MyBrandMadeImg";
import ListCardsModels from "@/components/ListCardsModels";
import MyTextBox from "@/components/ui/MyTextBox";
import MyPictureBox from "@/components/ui/MyPictureBox";
import ListSixItems from "@/components/ListSixItems";
import ModalVideoBox from "@/components/ModalVideoBox";
import ListCardsNews from "@/components/ListCardsNews";
import SectionGroup from "@/components/SectionGroup";
import MyVideoSwitcher from "@/components/ui/MyVideoSwitcher";

const store = useStore().state.homeModule
const storeNews = useStore().state.newsModule
</script>

<style scoped>
.safety-box {
  text-align: center;
  margin: auto;
}

@media (max-width: 992px) {
  .safety-box {
    text-align: left;
    margin: 0;
  }
}
</style>
