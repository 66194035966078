<template>
  <div class="control-slider">
    <button
      class="carousel-control-prev text-primary"
      :data-bs-target="'#' + target"
      data-bs-slide="prev"
    >
      <i class="fa-solid fa-arrow-left-long"></i>
    </button>

    <ul class="carousel-indicators">
      <li v-for="dot in slides"
          :key="dot.id"
      >
        <button
          :aria-current="{ active: dot.isActive }"
          :aria-label="'Slide ' + dot.id"
          :class="{ active: dot.isActive }"
          :data-bs-slide-to="(dot.id - 1)"
          :data-bs-target="'#' + target"
          :title="$t(dot.name)"
        />
      </li>
    </ul>

    <button
      class="carousel-control-next text-primary"
      :data-bs-target="'#' + target"
      data-bs-slide="next"
    >
      <i class="fa-solid fa-arrow-right-long"></i>
    </button>
  </div>
</template>

<script>
export default {
  props: ['slides', 'target']
};
</script>

<style scoped>
.carousel:hover .control-slider {
  bottom: 0;
}
.control-slider {
  position: absolute;
  z-index: 5;
  margin: 0 auto 0;
  width: 50%;
  left: 50%;
  bottom: -46px;
  transform: translate(-50%, 0);
  background-color: rgba(0,0,0,0.6);
  display: flex;
  justify-content: space-between;
  border-top: 2px solid var(--blue);
  padding: 7px 0 2px;
  transition: 0.7s;
  cursor: pointer;
}
.control-slider::after {
  content: "";
  position: absolute;
  z-index: 1;
  margin-left: -15px;
  left: 50%;
  top: -16px;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 15px solid var(--blue);
}

.carousel-control-prev,
.carousel-control-next {
  top: 0 !important;
  font-size: 2rem;
  opacity: 0.3;
}
.carousel-control-prev:hover,
.carousel-control-next:hover {
  opacity: 1;
}
.carousel-control-prev {
  left: 0;
}
.carousel-control-next {
  right: 0;
}

.carousel-indicators {
  position: relative;
  margin: auto;
}
.carousel-indicators button {
  position: relative;
  margin: 0 12px 0;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: var(--blue);
  opacity: 0.2;
}
.carousel-indicators button:hover {
  opacity: 1;
}
.carousel-indicators button.active {
  top: -3px;
  opacity: 1;
}
.carousel-indicators button.active::after {
  display: block;
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  border: 2px solid var(--blue);
  width: 28px;
  height: 28px;
}

@media (max-width: 1200px) {
  .control-slider {
    bottom: -43px;
  }
  .carousel-indicators .active {
    top: 0 !important;
  }
}
@media (max-width: 992px) {
  .control-slider {
    width: 65%;
    bottom: -44px;
  }
  .carousel-indicators .active {
    top: -1px !important;
  }
}
@media (max-width: 756px) {
  .control-slider {
    width: 95%;
    padding: 5px 0 0;
    z-index: 1;
    background-color: transparent;
    bottom: -50px !important;
    border-top: 0;
  }
  .control-slider::after {
    display: none;
  }
}
@media (max-width: 576px) {
  .control-slider {
    width: 85%;
    bottom: -40px !important;
  }
  .carousel-indicators {
    margin-top: -7px;
  }
  .carousel-indicators .active {
    top: 0 !important;
  }
  .carousel-indicators button {
    margin: 0 5px 0;
    width: 5px;
    height: 5px;
    border-radius: 50%;
  }
  .carousel-indicators .active::after {
    display: none !important;
  }
}
</style>
