<template>
  <strong
    v-if="subtitle"
    class="mb-2"
    :class="classes_strong"
  >
    {{ $t(subtitle)}}
  </strong>

  <h2 v-if="title">{{ $t(title)}}</h2>
  <p v-if="text1">{{ $t(text1)}}</p>
  <p v-if="text2">{{ $t(text2)}}</p>
  <p v-if="text3">{{ $t(text3)}}</p>

  <my-link
    v-if="href && link_text"
    :link_text="link_text"
    :classes="classes"
    :href="href"
  />
  <router-link
    v-if="router_link"
    :to="{ name: router_link, params: { locale: this.$i18n.locale }}"
    :class="classes"
    @click="moveUp"
  >
    {{ $t('page.learn_more') }}
  </router-link>
</template>

<script>
import MyLink from "@/components/ui/MyLink";

export default {
  components: { MyLink },
  props: [
    'subtitle',
    'title',
    'text1',
    'text2',
    'text3',
    'link_text',
    'classes',
    'href',
    'router_link',
    'classes_strong'
  ],

  setup() {
    return {
      moveUp: () => window.scrollTo(0,0)
    }
  }
};
</script>

<style scoped>
  strong {
    display: block;
    text-transform: uppercase;
    font-size: 1.3rem;
    opacity: 0.35;
  }
  .opacity-10 {
    opacity: 0.1;
  }

  @media (max-width: 1200px) {
    strong {
      font-size: 0.9rem;
    }
  }
  @media (max-width: 992px) {
    strong {
      font-size: 0.7rem;
    }
  }
</style>
