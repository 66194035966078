import { createI18n } from "vue-i18n";
import en from "./locales/en.json";
import uk from "./locales/uk.json";

export const languages = {
  en,
  uk,
};

export const localeStorageLang = localStorage.getItem('lang');
export const defaultLocale = process.env.VUE_APP_I18N_LOCALE;

export const i18n = createI18n({
  legacy: false,
  globalInjection: true,
  locale: localeStorageLang || defaultLocale,
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE,
  messages: { ...languages }
})
